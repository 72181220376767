import {
    authHeader,
    endPointConnect
  } from "../../utils/authHelper";
  import { KanbanBoardConstant } from "../constant";
  
  export const getKanbanBoardByCourseAndStudent = (courseId,studentId) => (dispatch) => {
    try {
      const requestData = {
        method: "GET",
        headers: authHeader()
      };
      endPointConnect(
       `lessons/kanban-board/${courseId}/student/${studentId}`,
        requestData,
        KanbanBoardConstant.GET_KANBAN_BOARD_DATA_SUCCESS,
        KanbanBoardConstant.GET_KANBAN_BOARD_DATA_FAIL,
        dispatch
      );
    } catch (err) {
      dispatch({
        type: KanbanBoardConstant.GET_KANBAN_BOARD_DATA_FAIL,
        payload: "",
        error: KanbanBoardConstant.DEFAULTERR,
      });
    }
  };

  export const updateKanbanBoard = (id,data) => (dispatch) => {
    try {
        const requestData = {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(data),
          };
      endPointConnect(
        `lessons/kanban-board/${id}`,
        requestData,
        KanbanBoardConstant.SAVE_KANBAN_BOARD_DATA_SUCCESS,
        KanbanBoardConstant.SAVE_KANBAN_BOARD_DATA_FAIL,
        dispatch
      );
    } catch (err) {
      dispatch({
        type: KanbanBoardConstant.SAVE_KANBAN_BOARD_DATA_FAIL,
        payload: "",
        error: KanbanBoardConstant.DEFAULTERR,
      });
    }
  };