export const TimeslotConstant = {
    DEFAULTERR: "sorry unable to process! please try again later",

    GET_TIMESLOT_SUCCESS : "GET_TIMESLOT_SUCCESS",
    GET_TIMESLOT_FAIL: "GET_TIMESLOT_FAIL",
    
    SAVE_TIMESLOT_SUCCESS : "SAVE_TIMESLOT_SUCCESS",
    SAVE_TIMESLOT_FAIL: "SAVE_TIMESLOT_FAIL",
    
    DELETE_TIMESLOT_SUCCESS : "DELETE_TIMESLOT_SUCCESS",
    DELETE_TIMESLOT_FAIL : "DELETE_TIMESLOT_FAIL",
}
