import React, { useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Col } from 'react-bootstrap';
const TodoColumn = React.memo(function TodoColumn({
  todoData,
  handleClickOpen,
  homeIndex,
  lesson,
  type,
  onShowPopup,
}) {
  let userDetail = JSON.parse(localStorage.getItem('userDetail'));
  const grid = 8;
  const setDragDataList = (stateData) => {
    return [
      {
        droppableId: 'todoContainer',
        tasks: stateData.items,
        taskCardColor: '#607d8b5e',
        dragCardColor: '#e9b86c',
      },
      {
        droppableId: 'onprogressContainer',
        tasks: stateData.progress,
        taskCardColor: '#e9b86c',
        dragCardColor: '#66bb6a9c',
      },
      {
        droppableId: 'completeContainer',
        tasks: stateData.completed,
        taskCardColor: '#66bb6a9c',
        dragCardColor: '#66bb6a9c',
      },
    ];
  };
  const [columns, setColumns] = useState(setDragDataList(todoData));
  useEffect(() => {
    setColumns(setDragDataList(todoData));
  }, [todoData]);
  const getItemStyle = (isDragging, draggableStyle, color, dragColor) => {
    // some basic styles to make the items look a bit nicer
    return {
      userSelect: 'none',
      padding: grid * 2,
      margin: `0 0 ${grid}px 0`,
      border: 1,
      borderRadius: 5,
      color: '#000',
      // change background colour if dragging
      background: isDragging ? dragColor : color,
      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver && "#69a4b4",
    padding: grid,
    minHeight: '100%',
  });
  return (
    <>
      {columns.map((data, index) => (
        <Col xs={4} sm={4} key={index}>
          <Droppable
            droppableId={data.droppableId}
            isDropDisabled={index <= homeIndex}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {data.tasks.map((item, index) => (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                          data.taskCardColor,
                          data.dragCardColor
                        )}
                        onClick={() => handleClickOpen(item)}
                      >
                        <span style={{ color: 'red' }}>{lesson.name}</span>
                        <div>{item.title}</div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {type === 'Homework' &&
                  data.droppableId === 'todoContainer' &&
                  userDetail?.role === 'Teacher' && (
                    <Button
                      title="Add Homework"
                      className="btn"
                      style={{
                        padding: 16,
                        margin: '0px 0px 8px',
                        border: '1px dashed #000',
                        borderRadius: '5px',
                        color: 'black',
                        width: '100%',
                        backgroundColor: '#add8e6',
                      }}
                      onClick={onShowPopup}
                    >
                      Add Homework
                    </Button>
                  )}
              </div>
            )}
          </Droppable>
        </Col>
      ))}
    </>
  );
});
export default TodoColumn;
