import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useLocation, useParams } from 'react-router-dom';
import EnrollStudentSection from './EnrollStudentSection';
import CourseAvailableSlots from './availableSlots';
import LessonList from './LessonList';
import CourseStudentList from './students';
import VocabLessons from './vocabs';

export default function SingleCourse(props) {
  const { id } = useParams();
  const { state } = useLocation();
  return (
    <div>
      <div className="h4">
        {state?.courseName}
        <br />
        <p style={{ marginTop: '5px' }}> {state?.description} </p>
      </div>
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="col-12 d-flex justify-content-between align-items-lg-center">
              Lessons
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <LessonList courseId={id} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <div className="col-12 d-flex justify-content-between align-items-lg-center">
              Vocab Lessons
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <VocabLessons courseId={id} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <div className="col-12 d-flex justify-content-between align-items-lg-center">
              Available Slots
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <CourseAvailableSlots courseId={id} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <div className="col-12 d-flex justify-content-between align-items-lg-center">
              Manage Students
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <EnrollStudentSection courseId={id} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            <div className="col-12 d-flex justify-content-between align-items-lg-center">
              Enrolled Students
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <CourseStudentList courseId={id} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
