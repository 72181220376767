import { authHeader, endPointConnect } from '../../utils/authHelper';
import { VocabConstant } from '../constant';

export const getAllVocabs = (courseId) => (dispatch) => {
  try {
    const requestData = {
      method: 'GET',
      headers: authHeader(),
    };
    endPointConnect(
      `vocabs/${courseId}`,
      requestData,
      VocabConstant.GET_VOCAB_DATA_SUCCESS,
      VocabConstant.GET_VOCAB_DATA_FAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: VocabConstant.GET_VOCAB_DATA_FAIL,
      payload: '',
      error: VocabConstant.DEFAULTERR,
    });
  }
};

export const saveVocab = (id, data) => (dispatch) => {
  try {
    const requestData = {
      method: id ? 'PUT' : 'POST',
      headers: authHeader(),
      body: JSON.stringify(data),
    };
    endPointConnect(
      id ? `vocabs/${id}` : `vocabs`,
      requestData,
      VocabConstant.SAVE_VOCAB_DATA_SUCCESS,
      VocabConstant.SAVE_VOCAB_DATA_FAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: VocabConstant.SAVE_VOCAB_DATA_FAIL,
      payload: '',
      error: VocabConstant.DEFAULTERR,
    });
  }
};

export const createVocabSourceAudio = (data, vocabSourceId) => (dispatch) => {
  try {
    const requestData = {
      method: 'POST',
      headers: { ...authHeader(true) },
      body: data,
    };
    endPointConnect(
      `vocabs/vocab-sources/${vocabSourceId}/vocab-source-audios`,
      requestData,
      VocabConstant.SAVE_VOCAB_SOURCE_AUDIO_SUCCESS,
      VocabConstant.SAVE_VOCAB_SOURCE_AUDIO_FAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: VocabConstant.SAVE_VOCAB_SOURCE_AUDIO_FAIL,
      payload: '',
      error: VocabConstant.DEFAULTERR,
    });
  }
};

export const getAllVocabSources = (vocabId) => (dispatch) => {
  try {
    const requestData = {
      method: 'GET',
      headers: authHeader(),
    };
    endPointConnect(
      `vocabs/vocab-sources/${vocabId}`,
      requestData,
      VocabConstant.GET_VOCAB_SOURCE_DATA_SUCCESS,
      VocabConstant.GET_VOCAB_SOURCE_DATA_FAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: VocabConstant.GET_VOCAB_SOURCE_DATA_FAIL,
      payload: '',
      error: VocabConstant.DEFAULTERR,
    });
  }
};

export const createVocabSource = (data, vocabId) => (dispatch) => {
  try {
    const requestData = {
      method: 'POST',
      headers: { ...authHeader(true) },
      body: data,
    };
    endPointConnect(
      `vocabs/vocab-sources/${vocabId}`,
      requestData,
      VocabConstant.SAVE_VOCAB_SOURCE_DATA_SUCCESS,
      VocabConstant.SAVE_VOCAB_SOURCE_DATA_FAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: VocabConstant.SAVE_VOCAB_SOURCE_DATA_FAIL,
      payload: '',
      error: VocabConstant.DEFAULTERR,
    });
  }
};
