import React, { useState } from 'react'

export default function AvailableSlotCell({ cell, changeSelectenrollCells }) {
  const [isChecked, setChecked] = useState(cell.isAvailable)
  return (
    <>
      <input type="checkbox" class="btn-check" name="options-outlined" id={`check-${cell._id}`} autocomplete="off" checked={isChecked}
        onChange={(e) => {
          setChecked(e.target.checked)
          changeSelectenrollCells(e.target.checked, cell.index)
        }}
      />
      <label className="btn btn-outline-success" htmlFor={`check-${cell._id}`}>Available</label>
    </>
  )
}
