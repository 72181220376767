export const homeworkConstant = {
  DEFAULTERR: 'sorry unable to process! please try again later',

  GET_HOMEWORK_DATA_SUCCESS: 'GET_HOMEWORK_DATA_SUCCESS',
  GET_HOMEWORK_DATA_FAIL: 'GET_HOMEWORK_DATA_FAIL',

  SAVE_HOMEWORK_DATA_SUCCESS: 'SAVE_HOMEWORK_DATA_SUCCESS',
  SAVE_HOMEWORK_DATA_FAIL: 'SAVE_HOMEWORK_DATA_FAIL',
  SAVE_HOMEWORK_DATA_REQUEST: 'SAVE_HOMEWORK_DATA_REQUEST',

  SAVE_HOMEWORK_TASK_SUCCESS: 'SAVE_HOMEWORK_TASK_SUCCESS',
  SAVE_HOMEWORK_TASK_FAIL: 'SAVE_HOMEWORK_TASK_FAIL',
  SAVE_HOMEWORK_TASK_REQUEST: 'SAVE_HOMEWORK_TASK_REQUEST',
};
