import { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  alertNotification,
  getCourses,
  getProfileData,
  getStudents,
  savePayment,
} from '../../redux/action';
import {
  CourseConstant,
  PaymentConstant,
  ProfileConstant,
} from '../../redux/constant';

const NewPayment = ({ showPopup, onClose, selectedPayment }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const course = useSelector((state) => state.course);
  const payment = useSelector((state) => state.payment);
  const profile = useSelector((state) => state.profile);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm();

  const [submitLoader, setSubmitLoader] = useState(false);
  const [courses, setCourses] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [submit, setSubmit] = useState(false);

  let userDetail = JSON.parse(localStorage.getItem('userDetail'));

  const onSubmit = (data) => {
    setSubmit(true);
    dispatch(
      savePayment({
        ...data,
        studentId: selectedStudent?.value,
        grade: selectedStudent?.grade,
        accountName: selectedStudent?.accountName,
        paymentId: selectedPayment?._id,
        status: userDetail?.role === 'Student' ? 'Pending' : 'Paid',
      })
    );
  };

  useEffect(() => {
    const { type, payload, error } = profile;
    if (type === ProfileConstant.GET_PROFILE_DATA_SUCCESS) {
      let paymentDate = selectedPayment?.date?.split('T')[0];
      reset({ ...payload?.results, ...selectedPayment, date: paymentDate });
      setSelectedStudent({
        value: payload?.results?._id,
        label: payload?.results?.firstName,
        grade: payload?.results?.grade,
        accountName: payload?.results?.accountName,
      });
    }
  }, [profile]);

  useEffect(() => {
    const { type, payload, error } = course;
    if (type === CourseConstant.GET_COURSE_DATA_SUCCESS) {
      setCourses(payload?.results);
    }
    if (type === CourseConstant.GET_STUDENTS_LIST_SUCCESS) {
      setStudentList(
        payload?.results?.map((stu) => {
          return {
            value: stu.id,
            label: stu.firstName,
            grade: stu.grade,
            accountName: stu.accountName,
          };
        })
      );
    }
  }, [course]);

  useEffect(() => {
    dispatch(getCourses());
  }, []);

  useEffect(() => {
    console.log(selectedPayment);
    if (showPopup) {
      if (userDetail?.role === 'Student') {
        fetchProfile();
      } else {
        if (!selectedPayment) {
          fetchUsers();
        } else {
          let paymentDate = selectedPayment?.date?.split('T')[0];
          reset({ ...selectedPayment, date: paymentDate });
          setSelectedStudent({
            value: selectedPayment?.student?._id,
            label: selectedPayment?.student?.firstName,
            grade: selectedPayment?.student?.grade,
            accountName: selectedPayment?.student?.accountName,
          });
        }
      }
    } else {
      reset({
        accountName: '',
        amount: 0,
        date: '',
        grade: '',
        month: 'January',
        referenceNo: '',
        status: '',
      });
    }
  }, [showPopup]);

  const fetchProfile = () => {
    let userDetail = JSON.parse(localStorage.getItem('userDetail'));
    if (userDetail?.role === 'Student') {
    }
    dispatch(getProfileData(userDetail?._id));
  };

  const fetchUsers = () => {
    dispatch(getStudents());
  };

  useEffect(() => {
    const { type, payload, error } = payment;
    if (type === PaymentConstant.SAVE_PAYMENT_DATA_SUCCESS) {
      dispatch(alertNotification(error, 'success'));
      onClose(true);
    }
    if (type === PaymentConstant.SAVE_PAYMENT_DATA_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
  }, [payment]);
  return (
    <Modal
      className="modal-modal-dialog-centered"
      show={showPopup}
      centered
      onHide={onClose}
    >
      <ModalHeader closeButton>
        {' '}
        {!selectedPayment ? 'New Payment' : 'Edit Payment'}
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="row form-row">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>Select Course</label>
                <select
                  className="form-control select"
                  {...register('courseId', { required: true })}
                >
                  {courses.map((course) => (
                    <option value={course._id}>{course.courseName}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>Select Month</label>
                <select
                  className="form-control select"
                  {...register('month', { required: true })}
                >
                  <option>January</option>
                  <option>February</option>
                  <option>March</option>
                  <option>April</option>
                  <option>May</option>
                  <option>June</option>
                  <option>July</option>
                  <option>Auguest</option>
                  <option>September</option>
                  <option>October</option>
                  <option>November</option>
                  <option>December</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>Payment Type</label>
                <select
                  className="form-control select"
                  {...register('paymentType', { required: true })}
                >
                  <option>Cash</option>
                  <option>Card</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>Reference No</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="#1234"
                  {...register('referenceNo', { required: true })}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>Amount</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('amount', { required: true })}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  {...register('date', { required: true })}
                />
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>Student Name</label>
                <Select
                  isDisabled={userDetail?.role === 'Student' || selectedPayment}
                  options={studentList}
                  value={selectedStudent}
                  onChange={(stu) => {
                    setSelectedStudent(stu);
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>Grade</label>
                <input
                  type="text"
                  disabled={userDetail?.role === 'Student' || selectedPayment}
                  className="form-control"
                  value={selectedStudent?.grade}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>Account Name</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={userDetail?.role === 'Student' || selectedPayment}
                  value={selectedStudent?.accountName}
                />
              </div>
            </div>
          </div>

          <div className="submit-section">
            <button
              onClick={handleSubmit(onSubmit)}
              className="btn btn-primary submit-btn"
            >
              Make Payment
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default NewPayment;
