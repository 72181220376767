import React, { useEffect, useState } from 'react';
import AddVocab from './AddVocab';
import { Link } from 'react-router-dom';
import { getAllVocabs } from '../../../redux/action/vocabAction';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { VocabConstant } from '../../../redux/constant';
import { alertNotification } from '../../../redux/action';

export default function VocabLessons({ courseId }) {
  const [vocabs, setVocabs] = useState([]);
  const [saveVocabPopup, setSaveVocabPopup] = useState(false);
  const [selectedVocab, setSelectedVocab] = useState(null);
  const dispatch = useDispatch();
  const vocab = useSelector((state) => state.vocab);
  let userDetail = JSON.parse(localStorage.getItem('userDetail'));
  useEffect(() => {
    dispatch(getAllVocabs(courseId));
  }, []);
  useEffect(() => {
    const { type, payload, error } = vocab;
    if (type === VocabConstant.GET_VOCAB_DATA_SUCCESS) {
      setVocabs(payload?.results);
    }
    if (type === VocabConstant.GET_VOCAB_DATA_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
    if (type === VocabConstant.SAVE_VOCAB_DATA_SUCCESS) {
      setSaveVocabPopup(false);
      dispatch(alertNotification(payload.message, 'success'));
      dispatch(getAllVocabs(courseId));
      setSelectedVocab(null);
    }
  }, [vocab]);
  return (
    <div className="row">
      {userDetail?.role == 'Teacher' && <div className="col-md-12 col-12">
        <div
          className="mentor-booking"
          style={{ width: '200px', marginBottom: '10px', float: 'right' }}
        >
          <a
            className="btn apt-btn"
            onClick={() => {
              setSaveVocabPopup(true);
            }}
          >
            Create New
          </a>
        </div>
        <AddVocab
          courseId={courseId}
          selectedVocab={selectedVocab}
          showPopup={saveVocabPopup}
          onClose={() => {
            setSelectedVocab(null);
            setSaveVocabPopup(false);
          }}
        />
      </div>}
      <div className="card card-table">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-hover table-center mb-0">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Section</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {vocabs.map((vocab, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{vocab.name}</td>
                    <td>
                      <a
                        href="#"
                        className="btn btn-sm bg-primary-light m-2"
                        onClick={() => {
                          setSaveVocabPopup(true);
                          setSelectedVocab(vocab);
                        }}
                      >
                        <i className="fas fa-edit" />
                      </a>
                      <Link
                        to={userDetail?.role === 'Student'?`/student/course/${courseId}/vocab-sources/${vocab._id}`:`/mentor/course/${courseId}/vocab-sources/${vocab._id}`}
                        className="btn btn-sm bg-primary-light m-2"
                        state={vocab}
                      >
                        <i className="fas fa-eye" />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
