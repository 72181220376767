import React, { useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { saveLesson } from '../../redux/action';

const LessonModal = ({ showPopup, selectedLesson, onClose, courseId }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (showPopup) {
      if (selectedLesson) {
        reset(selectedLesson)
    } else {
        reset({name:'',description:'',_id:'',color:'',courseId:courseId})
    }
    }
  },[showPopup])
  
  const onSaveLesson = (data) => {
    const {name,description,color,courseId,_id} = data
    dispatch(saveLesson(_id,{name,description,color,courseId}))
  }
  
  return (
    <Modal className="modal-modal-dialog-centered" show={showPopup} centered onHide={onClose}>
      <ModalHeader closeButton> {!selectedLesson ? 'Create Lesson' : 'Edit Lesson'}</ModalHeader>
      <ModalBody>
        <form>
          <div className="hours-info">
            <div className="row form-row hours-cont">
              <div className="col-12">
                <div className="row form-row">
                <div className="col-12 col-md-12">
                    <div className="form-group">
                      <label>Colour</label>
                      <input type="color" name="color" className="form-control" {...register("color", { required: true })} placeholder="Select Colour" />
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="form-group">
                      <label>Lesson Name</label>
                      <input type="text" name="lessonName" className="form-control" {...register("name", { required: true })} placeholder="Enter Name" />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Description</label>
                      <textarea type="text" name="lessonDescription" className="form-control" {...register("description", { required: true })} placeholder="Enter Description"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="submit-section text-center">
            <button type="submit" onClick={handleSubmit(onSaveLesson)} className="btn btn-primary submit-btn">Save Changes</button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}
export default LessonModal;