import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import VocabLessons from '.';

const CourseVocabs = () => {
    const {courseId} = useParams()

    useEffect(()=>{},[])
    return(
        <>
        <div className="row">
        <div className="col-md-12 col-12">
        
        </div>
      </div>
      <div className="row">
        <VocabLessons courseId={courseId}/>
        </div>
        </>
      )
}
export default CourseVocabs;