import { CourseConstant } from "../constant";
const initialState = {
    payload: "",
    type: '',
    error: ""
}

export default function courseReducers(state = initialState, action) {
    switch (action.type) {
        case CourseConstant.GET_COURSE_DATA_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_COURSE_DATA_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.SAVE_COURSE_DATA_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.SAVE_COURSE_DATA_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_LESSON_LIST_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_LESSON_LIST_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.SAVE_LESSON_DATA_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.SAVE_LESSON_DATA_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };

        case CourseConstant.GET_TASK_LIST_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_TASK_LIST_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_COURSE_STUDENT_LIST_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_COURSE_STUDENT_LIST_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_AVAILABLE_SLOTS_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_AVAILABLE_SLOTS_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_ENTROLL_COURSE_SLOTS_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_ENTROLL_COURSE_SLOTS_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.SAVE_AVAILABLE_SLOTS_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.SAVE_AVAILABLE_SLOTS_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_REQUEST_COURSE_STUDENT_LIST_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_REQUEST_COURSE_STUDENT_LIST_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_STUDENTS_LIST_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_STUDENTS_LIST_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_MENTOR_LIST_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.GET_MENTOR_LIST_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.ENROLL_COURSE_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.ENROLL_COURSE_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.ENROLL_STUDENT_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.ENROLL_STUDENT_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.SAVE_TASK_DATA_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case CourseConstant.SAVE_TASK_DATA_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        default:
            return state
    }
}