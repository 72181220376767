import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { alertNotification, registerUser } from '../../redux/action';
import { AuthConstant } from '../../redux/constant';

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [submitLoader, setSubmitLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [showPostRegisterMessage, setShowPostRegisterMessage] = useState(false);

  const onSubmit = (data) => {
    setSubmit(true);
    const { email, password, confirmPassword, firstName, lastName, role } =
      data;
    if (
      email &&
      password === confirmPassword &&
      firstName &&
      lastName &&
      role
    ) {
      setSubmitLoader(true);
      dispatch(
        registerUser({
          email,
          password,
          confirmPassword,
          firstName,
          lastName,
          role,
        })
      );
    }
  };

  useEffect(() => {
    const { type, payload, error } = auth;
    if (type === AuthConstant.REGISTERSUCCESS) {
      setSubmitLoader(false);
      setSubmit(false);
      if (payload) {
        const { message, success } = payload;
        if (success) {
          dispatch(alertNotification(message, 'success'));
          dispatch({
            type: 'REGISTER_SUCCESS',
            payload: null,
          });
          setShowPostRegisterMessage(true);
          reset({
            email: '',
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            role: '',
          });
        }
      }
    }

    if (type === AuthConstant.REGISTERFAIL) {
      const { message } = payload;
      setSubmitLoader(false);
      setSubmit(false);
      dispatch(alertNotification(message, 'fail'));
    }
  }, [auth]);

  return (
    <div className="bg-pattern-style bg-pattern-style-register">
      <div className="content">
        <div className="account-content">
          <div className="account-box">
            <div className="login-right">
              {!showPostRegisterMessage && (
                <div className="login-header">
                  <h3>
                    <span>Genius Mentors</span> Register
                  </h3>
                  <p className="text-muted">Access to our dashboard</p>
                </div>
              )}
              {
                !showPostRegisterMessage &&
                <form>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">First Name</label>
                        <input
                          id="first-name"
                          type="text"
                          className="form-control"
                          name="first_name"
                          {...register('firstName', {
                            required: true,
                          })}
                        />
                        {
                          errors.firstName && <span>Please enter first name</span>
                        }
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">Last Name</label>
                        <input
                          id="last-name"
                          type="text"
                          className="form-control"
                          name="last_name"
                          {...register('lastName', {
                            required: true,
                          })}
                        />
                        {
                          errors.lastName && <span>Please enter last name</span>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-control-label">Email Address</label>
                    <input
                      id="email"
                      type="email"
                      className="form-control"
                      {...register('email', {
                        required: true,
                      })}
                    />
                    {
                      errors.email && <span>Please enter email address</span>
                    }
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">Password</label>
                        <input
                          id="password"
                          type="password"
                          className="form-control"
                          name="password"
                          {...register('password', {
                            required: true,
                            minLength: 8,
                          })}
                        />
                        {
                          errors.password && <span>Please enter password</span>
                        }
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">
                          Confirm Password
                        </label>
                        <input
                          id="password-confirm"
                          type="password"
                          className="form-control"
                          name="password_confirmation"
                          {...register('confirmPassword', {
                            required: true,
                            minLength: 8,
                          })}
                        />
                        {
                          errors.confirmPassword && <span>Please enter confirm password</span>
                        }
                      </div>
                    </div>
                    <div className="col-12 col-md-12">
                      <div className="form-group">
                        <label>Register as</label>
                        <select
                            className="form-control select"
                          defaultValue="Student"
                          {...register('role', {
                            required: true,
                          })}
                        >
                          <option value="Teacher">Mentor</option>
                          <option value="Student">Student</option>
                        </select>
                        {
                          errors.role && <span>Please enter role</span>
                        }
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group">
                  <div className="custom-control custom-control-xs custom-checkbox">
                    <input type="checkbox" className="custom-control-input" name="agreeCheckboxUser" id="agree_checkbox_user" />
                    <label className="custom-control-label" htmlFor="agree_checkbox_user">I agree to Mentoring</label> <a>Privacy Policy</a> &amp; <a> Terms.</a>
                  </div>
                </div> */}
                  <button
                    className="btn btn-primary login-btn"
                    type="submit"
                    disabled={submitLoader}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {submitLoader ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : (
                      'Create Account'
                    )}
                  </button>
                  <div className="account-footer text-center mt-3">
                    Already have an account?{' '}
                    <Link className="forgot-link mb-0" to="/login">
                      Login
                    </Link>
                  </div>
                </form>
              }

              {showPostRegisterMessage && (
                <div>
                  <h3 style={{ color: 'green' }}>Account Registered successfully. Please check your mail for verify email address.</h3>
                  <span>It may take 4 - 5 minutes.</span>
                  <button
                    className="btn btn-primary login-btn"
                    type="submit"
                    disabled={submitLoader}
                    onClick={() => { navigate('/login') }}
                  >
                    Login
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
