import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Header from '../components/layout/header';
import ForgotPassword from '../pages/auth/forgotPassword';
import Login from '../pages/auth/login';
import Register from '../pages/auth/register';
import { Alert } from '../utils/alert';
import Activate from './auth/activate';
import ResetPassword from './auth/resetPassword';
import HomePage from './home';
import MentorRoute from './mentor';
import StudentRoute from './student';

function App() {
  const [alertNotification, setAlertNotification] = useState(false);
  const [notificationContent, setNotificationContent] = useState('');
  const [typeOfMsg, setTypeOfMsg] = useState('');
  const alertNotify = useSelector((state) => state.alertNotification);
  useEffect(() => {
    const { type, payload } = alertNotify;
    if (type && payload) {
      setAlertNotification(true);
      setTypeOfMsg(type);
      setNotificationContent(payload);
    }
  }, [alertNotify]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      {alertNotification &&
        notificationContent &&
        Alert(notificationContent, typeOfMsg)}
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/main/activate-account/:key" element={<Activate />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/users/password/reset/:token"
            element={<ResetPassword />}
          />
          <Route path="/student/*" element={<StudentRoute />} />
          <Route path="/mentor/*" element={<MentorRoute />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
