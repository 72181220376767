import { authHeader, endPointConnect } from '../../utils/authHelper';
import { AuthConstant } from '../constant';

export const login = (data) => (dispatch) => {
  try {
    const { email, password, googleSigninId } = data;
    const requestData = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        email: email ? email : '',
        password: password ? password : '',
        signInGoogleId: googleSigninId ? googleSigninId : null,
      }),
    };
    endPointConnect(
      !googleSigninId ? 'login' : 'google-signin',
      requestData,
      AuthConstant.LOGINSUCCESS,
      AuthConstant.LOGINFAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: AuthConstant.LOGINFAIL,
      payload: '',
      error: AuthConstant.DEFAULTERR,
    });
  }
};

export const registerUser = (data) => (dispatch) => {
  try {
    const { email, password, firstName, lastName, role } = data;
    const requestData = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        email: email ? email : '',
        password: password ? password : '',
        firstName: firstName ? firstName : '',
        lastName: lastName ? lastName : '',
        role: role ? role : '',
      }),
    };
    endPointConnect(
      'register',
      requestData,
      AuthConstant.REGISTERSUCCESS,
      AuthConstant.REGISTERFAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: AuthConstant.REGISTERFAIL,
      payload: '',
      error: AuthConstant.DEFAULTERR,
    });
  }
};

export const activateAccount = (key) => (dispatch) => {
  try {
    const requestData = {
      method: 'GET',
      headers: authHeader(),
    };
    endPointConnect(
      'activation/' + key,
      requestData,
      AuthConstant.VERIFYSUCCESS,
      AuthConstant.VERIFYFAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: AuthConstant.VERIFYFAIL,
      payload: '',
      error: AuthConstant.DEFAULTERR,
    });
  }
};

export const forgotPassword = (data) => (dispatch) => {
  try {
    const { email } = data;
    const requestData = {
      method: 'put',
      headers: authHeader(),
      body: JSON.stringify({
        email: email ? email : '',
      }),
    };
    endPointConnect(
      'forgotpassword',
      requestData,
      AuthConstant.FORGOT_PASSWORD_SUCCESS,
      AuthConstant.FORGOT_PASSWORD_FAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: AuthConstant.FORGOT_PASSWORD_FAIL,
      payload: '',
      error: AuthConstant.DEFAULTERR,
    });
  }
};

export const resetPassword = (data) => (dispatch) => {
  try {
    const requestData = {
      method: 'put',
      headers: authHeader(),
      body: JSON.stringify(data),
    };
    endPointConnect(
      'resetpassword',
      requestData,
      AuthConstant.RESET_PASSWORD_SUCCESS,
      AuthConstant.RESET_PASSWORD_FAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: AuthConstant.RESET_PASSWORD_FAIL,
      payload: '',
      error: AuthConstant.DEFAULTERR,
    });
  }
};
