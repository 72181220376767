import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { alertNotification } from "../../redux/action";
import { deleteAnswer, getQuestion } from "../../redux/action/forumActions";
import { ForumConstant } from "../../redux/constant";
import SaveAnswerModal from "./saveAnswer";

const SingleQuestion = () => {
    const { questionId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const forum = useSelector((state) => state.forum);
    const { selectedQuestion } = forum;
    let userDetail = JSON.parse(localStorage.getItem('userDetail'))
    const [saveAnswerPopup, setSaveAnswerPopup] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState(null);

    useEffect(() => {
        dispatch(getQuestion(questionId))
    }, []);

    useEffect(() => {
        const { type, error } = forum;
        if (type === ForumConstant.SAVE_ANSWER_SUCCESS) {
            dispatch(alertNotification(error, 'success'));
            setSaveAnswerPopup(false);
            setSelectedAnswer(null)
            dispatch(getQuestion(questionId));
        }
        if (type === ForumConstant.SAVE_ANSWER_FAIL) {
            dispatch(alertNotification(error, 'fail'));
        }

        if (type === ForumConstant.DELETE_ANSWER_SUCCESS) {
            dispatch(alertNotification(error, 'success'));
            dispatch(getQuestion(questionId));
        }
        if (type === ForumConstant.DELETE_ANSWER_FAIL) {
            dispatch(alertNotification(error, 'fail'));
        }
    }, [forum]);

    return (
        <>
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="card">
                        <div className="card-headers">
                            <h1>{selectedQuestion?.question?.title}</h1>
                            <div dangerouslySetInnerHTML={{ __html: selectedQuestion?.question?.content }}></div>
                        </div>
                    </div>

                    {
                        selectedQuestion?.answers?.map((ans) => {
                            return (
                                <div className="card" key={ans._id}>
                                    <div className="card-body">
                                        <p  style={{backgroundColor: '#ccc'}}>{ans.title}</p>
                                        <div dangerouslySetInnerHTML={{ __html: ans.content }}></div>

                                        <div style={{marginTop: '10px',color: '#ccc'}}>
                                            by {ans.answerBy.firstName} {ans.answerBy.lastName}
                                        </div>

                                    </div>
                                    {
                                        userDetail?.role === 'Teacher' &&

                                        <div>
                                            <Link
                                                onClick={() => {
                                                    setSaveAnswerPopup(true)
                                                    setSelectedAnswer(ans)
                                                }}
                                                className="btn btn-sm bg-primary-light m-2"
                                            >
                                                Edit Answer
                                            </Link>
                                            <Link
                                                onClick={() => {
                                                    if (window.confirm("Delete answer?")) {
                                                        dispatch(deleteAnswer(ans._id))
                                                    }
                                                }}
                                                className="btn btn-sm bg-danger-light m-2"
                                            >
                                                Delete Answer
                                            </Link>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div>
                {
                    userDetail?.role === 'Teacher' &&
                    <Link
                        onClick={() => {
                            setSaveAnswerPopup(true)
                        }}
                        className="btn btn-sm bg-primary-light m-2"
                    >
                        Add Answer
                    </Link>
                }
            </div>
            <SaveAnswerModal
                questionId={questionId}
                selectedAnswer={selectedAnswer}
                showPopup={saveAnswerPopup}
                onClose={() => {
                    setSaveAnswerPopup(false);
                    setSelectedAnswer(null)
                }}
            />
        </>
    )
}

export default SingleQuestion;