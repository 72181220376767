import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  alertNotification,
  deleteTimeslot,
  getTimeslots,
} from '../../redux/action';
import { TimeslotConstant } from '../../redux/constant';
import SaveTimeslot from './saveTimeSlot';

const TimeSlots = () => {
  const [timeslots, setTimeslots] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timeslot = useSelector((state) => state.timeslot);
  const [saveTimeslotPopup, setSaveTimeslotPopup] = useState(false);

  let userDetail = JSON.parse(localStorage.getItem('userDetail'));
  const { _id } = userDetail;

  useEffect(() => {
    dispatch(getTimeslots(_id));
  }, []);

  useEffect(() => {
    const { type, payload, error } = timeslot;
    if (type === TimeslotConstant.GET_TIMESLOT_SUCCESS) {
      setTimeslots(payload?.results);
    }
    if (type === TimeslotConstant.GET_TIMESLOT_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
    if (type === TimeslotConstant.DELETE_TIMESLOT_SUCCESS) {
      dispatch(alertNotification(error, 'success'));
      dispatch(getTimeslots(_id));
    }
    if (type === TimeslotConstant.DELETE_TIMESLOT_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }

    if (type === TimeslotConstant.SAVE_TIMESLOT_SUCCESS) {
      dispatch(alertNotification(error, 'success'));
      setSaveTimeslotPopup(false);
      dispatch(getTimeslots(_id));
    }
    if (type === TimeslotConstant.SAVE_TIMESLOT_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
  }, [timeslot]);

  return (
    <>
      <div className="row">
        <div className="col-md-12 col-12">
          <div
            className="mentor-booking"
            style={{ width: '200px', marginBottom: '10px', float: 'right' }}
          >
            <a
              className="btn apt-btn"
              onClick={() => {
                setSaveTimeslotPopup(true);
              }}
            >
              New Timeslot
            </a>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-12">
          <div className="card card-table">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Slot</th>
                      <th>Actions</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {timeslots?.map((timeslotRec, i) => {
                      const { id, slot } = timeslotRec;
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{slot}</td>
                          <td>
                            <a
                              onClick={() => {
                                if (
                                  window.confirm(
                                    'Are you sure about to delete?'
                                  )
                                ) {
                                  dispatch(deleteTimeslot(id));
                                }
                              }}
                              className="btn btn-sm bg-danger-light m-2"
                            >
                              <i className="fas fa-trash" />
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SaveTimeslot
        showPopup={saveTimeslotPopup}
        onClose={() => {
          setSaveTimeslotPopup(false);
        }}
      />
    </>
  );
};

export default TimeSlots;
