import React, { useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { saveVocab } from '../../../redux/action/vocabAction';

export default function AddVocab({
  showPopup,
  selectedVocab,
  onClose,
  courseId,
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (showPopup) {
      if (selectedVocab) {
        reset(selectedVocab);
      } else {
        reset({
          name: '',
          description: '',
          _id: '',
          courseId: courseId,
        });
      }
    }
  }, [showPopup]);

  const onSaveVocab = (data) => {
    const { name, _id } = data;
    console.log(data);
    dispatch(saveVocab(_id, { name, courseId }));
  };
  return (
    <Modal
      className="modal-modal-dialog-centered"
      show={showPopup}
      centered
      onHide={onClose}
    >
      <ModalHeader closeButton>
        {!selectedVocab ? 'Create Vocab' : 'Edit Vocab'}
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="hours-info">
            <div className="row form-row hours-cont">
              <div className="col-12">
                <div className="row form-row">
                  <div className="col-12 col-md-12">
                    <div className="form-group">
                      <label>Vocab Name</label>
                      <input
                        type="text"
                        name="vocabName"
                        className="form-control"
                        {...register('name', { required: true })}
                        placeholder="Enter Name"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Description</label>
                      <textarea
                        type="text"
                        name="lessonDescription"
                        className="form-control"
                        {...register('description')}
                        placeholder="Enter Description"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="submit-section text-center">
            <button
              type="submit"
              onClick={handleSubmit(onSaveVocab)}
              className="btn btn-primary submit-btn"
            >
              Save Changes
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}
