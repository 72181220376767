import { VocabConstant } from '../constant';

const initialState = {
  payload: '',
  type: '',
  error: '',
};

export default function vocabReducer(state = initialState, action) {
  switch (action.type) {
    case VocabConstant.GET_VOCAB_DATA_SUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
      };
    case VocabConstant.GET_VOCAB_DATA_FAIL:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
      };
    case VocabConstant.SAVE_VOCAB_DATA_SUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
      };
    case VocabConstant.SAVE_VOCAB_DATA_FAIL:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
      };
    case VocabConstant.GET_VOCAB_SOURCE_DATA_SUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
      };
    case VocabConstant.GET_VOCAB_SOURCE_DATA_FAIL:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
      };
    case VocabConstant.SAVE_VOCAB_SOURCE_DATA_SUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
      };
    case VocabConstant.SAVE_VOCAB_SOURCE_DATA_FAIL:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
      };
    case VocabConstant.GET_VOCAB_SOURCE_AUDIO_SUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
      };
    case VocabConstant.GET_VOCAB_SOURCE_AUDIO_FAIL:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
      };
    case VocabConstant.SAVE_VOCAB_SOURCE_AUDIO_SUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
      };
    case VocabConstant.SAVE_VOCAB_SOURCE_AUDIO_FAIL:
      return {
        payload: action.payload,
        type: action.type,
        error: action.error,
      };
    default:
      return state;
  }
}
