import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { alertNotification, updateKanbanBoard } from '../../redux/action';
import { KanbanBoardConstant } from '../../redux/constant';
import TodoColumn from './TodoColumn';
import TaskModal from '../course/TaskModal';

export default function ToDoContainer({ lesson, courseName, student }) {
  const [state, setState] = useState({
    items: [],
    progress: [],
    completed: [],
  });
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    const { todoTasks, completedTasks, progressTasks } = lesson.tasks;
    setState({
      items: todoTasks,
      progress: progressTasks,
      completed: completedTasks,
    });
  }, [lesson]);
  const [task, setTask] = useState();
  const [open, setOpen] = React.useState(false);
  const columnOrder = [
    'todoContainer',
    'onprogressContainer',
    'completeContainer',
  ];

  const { courseId, studentId } = useParams();
  const dispatch = useDispatch();
  const kanbanBoard = useSelector((state) => state.kanbanBoard);
  const { type, payload, error } = kanbanBoard;
  const kanbanBoardId = payload?.results?.id;

  useEffect(() => {
    if (type === KanbanBoardConstant.SAVE_KANBAN_BOARD_DATA_SUCCESS) {
      dispatch(alertNotification(payload.message, 'success'));
      setLoading(false);
    }
  }, [kanbanBoard]);
  const handleClickOpen = (item) => {
    setTask(item);
    setOpen(true);
  };
  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const [homeIndex, setHomeIndex] = useState(null);
  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  const id2List = {
    todoContainer: 'items',
    onprogressContainer: 'progress',
    completeContainer: 'completed',
  };
  const id2Array = {
    todoContainer: 'todoTasks',
    onprogressContainer: 'progressTasks',
    completeContainer: 'completedTasks',
  };
  const getList = (id) => state[id2List[id]];
  const onDragStart = (start) => {
    const index = columnOrder.indexOf(start.source.droppableId);
    setHomeIndex(index);
  };

  const onDragEnd = (result) => {
    setHomeIndex(null);
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    console.log({
      dropId: source.droppableId,
      destination: destination.droppableId,
    });
    console.log({ sourceIndex: source.index, destIndex: destination.index });
    if (source.droppableId !== destination.droppableId) {
      const sourceArr = getList(source.droppableId);
      const destinationArr = getList(destination.droppableId);
      const resultArr = move(sourceArr, destinationArr, source, destination);
      let mailBody = {
        from: id2Array[source.droppableId],
        to: id2Array[destination.droppableId],
        mailContent: `<h4>Task is changed to ${
          destination.droppableId === 'onprogressContainer'
            ? 'On going'
            : 'Completed'
        }</h4><ul><li>Course : ${courseName}</li><li>Lesson : ${
          lesson.name
        }</li><li>Task : ${sourceArr[source.index].title}</li></ul>`,
        studentEmail: student.email,
      };
      console.log(mailBody);

      let items = resultArr.todoContainer
        ? resultArr.todoContainer
        : state.items;
      let progress = resultArr.onprogressContainer
        ? resultArr.onprogressContainer
        : state.progress;
      let completed = resultArr.completeContainer
        ? resultArr.completeContainer
        : state.completed;

      const createData = {
        lessonId: lesson.id,
        todoTasks: convertToRequestJson(items),
        progressTasks: convertToRequestJson(progress),
        completedTasks: convertToRequestJson(completed),
        mailBody,
      };
      dispatch(updateKanbanBoard(kanbanBoardId, createData));
      setLoading(true);
    }
  };
  const convertToRequestJson = (data) => {
    return data.map((t) => t._id);
  };
  return (
    <>
      {!isLoading ? (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Row>
            <TodoColumn
              lesson={lesson}
              todoData={state}
              handleClickOpen={handleClickOpen}
              homeIndex={homeIndex}
            />
          </Row>
          <TaskModal
            selectedTask={task}
            showPopup={open}
            onClose={() => {
              setTask(null);
              setOpen(false);
            }}
          />
        </DragDropContext>
      ) : (
        <Row className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Row>
      )}
    </>
  );
}
