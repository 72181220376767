import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { BLOG_01 } from '../../constant/imagePaths';
import { alertNotification, getCourses } from '../../redux/action';
import { CourseConstant } from '../../redux/constant';
import CourseForm from './CourseForm';
const CourseList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [saveCoursePopup, setSaveCoursePopup] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const course = useSelector((state) => state.course);
  let userDetail = JSON.parse(localStorage.getItem('userDetail'));

  useEffect(() => {
    dispatch(getCourses());
  }, []);

  useEffect(() => {
    const { type, payload, error } = course;
    if (type === CourseConstant.GET_COURSE_DATA_SUCCESS) {
      setCourses(payload?.results);
    }
    if (type === CourseConstant.GET_COURSE_DATA_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
    if (type === CourseConstant.SAVE_COURSE_DATA_SUCCESS) {
      dispatch(alertNotification(error, 'success'));
      setSelectedCourse(null);
      setSaveCoursePopup(false);
      dispatch(getCourses());
    }
    if (type === CourseConstant.SAVE_COURSE_DATA_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
  }, [course]);

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
          result[3],
          16
        )},0.3)`
      : null;
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12 col-12">
          {userDetail?.role === 'Teacher' && (
            <div
              className="mentor-booking"
              style={{ width: '200px', marginBottom: '10px', float: 'right' }}
            >
              <Link
                className="btn apt-btn"
                onClick={() => {
                  setSelectedCourse(null);
                  setSaveCoursePopup(true);
                }}
              >
                Create Course
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        {courses.length > 0 ? (
          courses.map((courseRec, i) => {
            const { _id, courseName, description, color } = courseRec;
            return (
              <div className="col-12 col-md-6 col-xl-4" key={_id}>
                <div
                  className="course-box blog grid-blog"
                  style={{ backgroundColor: hexToRgb(color) }}
                >
                  <div className="blog-image mb-0">
                    <Link
                      to={
                        userDetail?.role === 'Student'
                          ? `/student/courses/${_id}/kanban-board`
                          : `/mentor/course/${_id}`
                      }
                    >
                      <img
                        className="img-fluid"
                        src={BLOG_01}
                        alt="Post Image"
                      />
                    </Link>
                  </div>
                  <div className="course-content">
                    {/* <span className="date">April 09 2020</span> */}
                    <span className="course-title">{courseName}</span>
                    <p>{description}</p>
                    <div className="row">
                      {userDetail?.role === 'Teacher' && (
                        <>
                          <div className="col">
                            <Link
                              onClick={() => {
                                setSelectedCourse(courseRec);
                                setSaveCoursePopup(true);
                              }}
                              className="text-success"
                            >
                              <i className="far fa-edit" /> Edit
                            </Link>
                          </div>
                          <div className="col text-right">
                            <Link
                              to={`/mentor/course/${_id}`}
                              state={courseRec}
                              className="text-danger"
                            >
                              <i className="far fa-eye" /> View
                            </Link>
                          </div>
                        </>
                      )}
                      {userDetail?.role === 'Student' && (
                        <div className="col d-flex justify-content-between">
                          <Link
                            to={`/student/courses/${_id}/kanban-board`}
                            state={courseRec}
                            className="text-danger"
                          >
                            <i className="far fa-eye" /> Go to tasks
                          </Link>
                          <Link
                            to={`/student/courses/${_id}/home-work`}
                            state={courseRec}
                            className="text-secondary"
                          >
                            <i className="far fa-eye" /> Go to Homework
                          </Link>
                          <Link
                            to={`/student/courses/${_id}/vocab-sources`}
                            className="text-secondary"
                          >
                            <i className="far fa-eye" /> View Vocabs
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <>
            {userDetail?.role === 'Teacher' ? (
              <Alert variant="warning">
                <Alert.Heading>
                  Courses not found.Please create Course.
                </Alert.Heading>
              </Alert>
            ) : (
              <Alert variant="warning">
                <Alert.Heading>
                  You don't have enrolled courses. Please enroll course in
                  mentors page.
                </Alert.Heading>
              </Alert>
            )}
          </>
        )}
      </div>
      <CourseForm
        selectedCourse={selectedCourse}
        showPopup={saveCoursePopup}
        onClose={() => {
          setSelectedCourse(null);
          setSaveCoursePopup(false);
        }}
      />
    </>
  );
};

export default CourseList;
