import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { saveTask } from '../../redux/action';

const SaveTaskModal = ({ showPopup, selectedTask, onClose, lessonId }) => {
  const dispatch = useDispatch();
  const [content, setContent] = useState({});
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (showPopup) {
      if (selectedTask) {
        reset(selectedTask);
        setContent(selectedTask?.content);
      } else {
        reset({ title: '', type: '', _id: '', lessonId: lessonId });
        setContent({});
      }
    }
  }, [showPopup]);

  const onSaveTask = (data) => {
    const { title, type, _id } = data;
    dispatch(saveTask(_id, { title, type, lessonId, content }));
  };

  return (
    <Modal
      size="xl"
      fullscreen={false}
      className="modal-modal-dialog-centered"
      show={showPopup}
      centered
      onHide={() => {
        setContent({})
        onClose()
      }}
    >
      <ModalHeader closeButton>
        {!selectedTask ? 'Create Task' : 'Edit Task'}
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="hours-info">
            <div className="row form-row hours-cont">
              <div className="col-12">
                <div className="row form-row">
                  <div className="col-12 col-md-12">
                    <div className="form-group">
                      <label>Task Title</label>
                      <input
                        type="text"
                        name="taskName"
                        className="form-control"
                        {...register('title', { required: true })}
                        placeholder="Enter Name"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Type</label>
                      <select
                        className="form-control select"
                        {...register('type', { required: true })}
                      >
                        <option>Test</option>
                        <option>Homework</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <CKEditor
                        editor={ClassicEditor}
                        data={content ? content : {}}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setContent(data);
                          console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="submit-section text-center">
            <button
              type="submit"
              onClick={handleSubmit(onSaveTask)}
              className="btn btn-primary submit-btn"
            >
              Save Changes
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default SaveTaskModal;
