import React, { useState } from 'react'

export default function EntrollSlotCell({cell, changeSelectenrollCells}) {
    const [isChecked,setChecked] = useState(false)
  return (
    <>
      <input type="checkbox" class="btn-check" name="options-outlined" id={`check-${cell._id}`} autocomplete="off" disabled={!cell.isAvailable} checked={isChecked}
        onChange={(e) => {
          setChecked(e.target.checked)
          changeSelectenrollCells(e.target.checked,cell.index)
      }} />
    <label className={`${cell.isAvailable ? 'btn btn-outline-success' : 'btn btn-outline-danger'}`} htmlFor={`check-${cell._id}`}> {cell.isAvailable ? 'Available' : 'Not Available'} </label>
    </>
  )
}
