import React, { useState } from 'react';


export default function StudentEnrollCell({ cell, handleClickOpen }) {
  const [isExpand, setExpand] = useState(false);



  return (
    <>
      {isExpand &&
        (cell.requestStudents.length > 0 || cell.enrolledStudents.length > 0) ? (
        <>
          <div
            style={{
              backgroundColor: 'red',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              marginBottom: 3,
              width: 250,
            }}
            onClick={() => setExpand(false)}
          >
            close the details
          </div>
          {cell.enrolledStudents?.map((cstudent) => (
            <div
              key={`${cstudent.id}-${cell.index}`}
              style={{
                height: 30,
                backgroundColor: '#098c53',
                color: '#fff',
                marginBottom: 3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p>
                {cstudent?.firstName} {cstudent?.lastName}
              </p>
            </div>
          ))}
          {cell.requestStudents?.map((cstudent) => (
            <div
              key={`req-${cstudent.id}-${cell.index}`}
              style={{
                height: 30,
                // backgroundColor: '#f4ae36',
                border: '1px solid #00e65b',
                color: '#00e65b',
                marginBottom: 3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleClickOpen(cstudent, cell.index)
              }}
            >
              <p>
                {cstudent?.firstName} {cstudent?.lastName}
              </p>
            </div>
          ))}
        </>
      ) : (
        <div
          style={{
            width: 250,
            color: '#fff',
            marginBottom: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            cursor:
              cell.requestStudents.length > 0 ||
                cell.enrolledStudents.length > 0
                ? 'pointer'
                : '',
          }}
          className="enroll-cell"
          onClick={() => setExpand(true)}
        >
          <div
            style={{
              width: '50%',
              // backgroundColor: '#f4ae36',
              border: '1px solid #00e65b',
              color: '#00e65b',
              display: 'flex',
              justifyContent: 'center',
              // borderRight: 'solid #0000FF',
              marginRight: '2px',
              flexDirection: 'column',
              borderRadius: '5px'
            }}
          >
            <span style={{ justifySelf: 'center', alignSelf: 'center' }}>
              new students
            </span>
            <span style={{ justifySelf: 'center', alignSelf: 'center' }}>
              {cell.requestStudents.length}
            </span>
          </div>
          <div
            style={{
              width: '50%',
              backgroundColor: '#098c53',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              borderRadius: '5px'
            }}
          >
            <span style={{ justifySelf: 'center', alignSelf: 'center' }}>
              Enrolled students
            </span>
            <span style={{ justifySelf: 'center', alignSelf: 'center' }}>
              {cell.enrolledStudents.length}
            </span>
          </div>
        </div>
      )}
    </>
  );
}
