import {
  authHeader,
  endPointConnect
} from "../../utils/authHelper";
import { PaymentConstant } from "../constant";

export const getPayments = () => (dispatch) => {
  let userDetail = JSON.parse(localStorage.getItem('userDetail'))
  const { role, _id } = userDetail;
  try {
    const requestData = {
      method: "GET",
      headers: authHeader()
    };
    endPointConnect(
      role === 'Student' ? 'payments/' + _id : "payments",
      requestData,
      PaymentConstant.GET_PAYMENT_DATA_SUCCESS,
      PaymentConstant.GET_PAYMENT_DATA_FAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: PaymentConstant.GET_PAYMENT_DATA_FAIL,
      payload: "",
      error: PaymentConstant.DEFAULTERR,
    });
  }
};

export const savePayment = (data) => (dispatch) => {
  const {
    accountName,
    amount,
    date,
    grade,
    month,
    referenceNo,
    status,
    paymentId,
    studentId,
    courseId
  } = data;
  try {
    const requestData = {
      method: paymentId ? "PUT" : "POST",
      headers: authHeader(),
      body: JSON.stringify({
        studentId: studentId,
        accountName,
        amount,
        date,
        grade,
        month,
        referenceNo,
        status,
        courseId
      })
    };
    endPointConnect(
      paymentId ? "payments/" + paymentId : "payments",
      requestData,
      PaymentConstant.SAVE_PAYMENT_DATA_SUCCESS,
      PaymentConstant.SAVE_PAYMENT_DATA_FAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: PaymentConstant.SAVE_PAYMENT_DATA_FAIL,
      payload: "",
      error: PaymentConstant.DEFAULTERR,
    });
  }
};


export const acceptPayment = (paymentId) => (dispatch) => {
  try {
    const requestData = {
      method: "PUT",
      headers: authHeader()
    };
    endPointConnect(
      "payments/accept/" + paymentId,
      requestData,
      PaymentConstant.ACCEPT_PAYMENT_SUCCESS,
      PaymentConstant.ACCEPT_PAYMENT_FAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: PaymentConstant.ACCEPT_PAYMENT_FAIL,
      payload: "",
      error: PaymentConstant.DEFAULTERR,
    });
  }
};

export const rejectPayment = (paymentId) => (dispatch) => {
  try {
    const requestData = {
      method: "PUT",
      headers: authHeader()
    };
    endPointConnect(
      "payments/reject/" + paymentId,
      requestData,
      PaymentConstant.REJECT_PAYMENT_SUCCESS,
      PaymentConstant.REJECT_PAYMENT_FAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: PaymentConstant.REJECT_PAYMENT_FAIL,
      payload: "",
      error: PaymentConstant.DEFAULTERR,
    });
  }
};
