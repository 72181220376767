import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { weeks } from '../../data';
import { accpetRejectStudent, alertNotification, getAvailableSlots, getRequestCourseStudents } from '../../redux/action';
import { CourseConstant } from '../../redux/constant';
import StudentEnrollCell from './StudentEnrollCell';

export default function EnrollStudentSection({ courseId }) {
  const [availableTimeSlots, setAvailableTimeSlots] = useState([])
  const [entrollStudents, setEntrollStudents] = useState([])
  const [showPopup, setShowPopup] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const course = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getAvailableSlots(courseId))
  }, []);

  useEffect(() => {
    const { type, payload, error } = course;
    if (type === CourseConstant.GET_AVAILABLE_SLOTS_SUCCESS) {
      setAvailableTimeSlots(payload?.results)
      dispatch(getRequestCourseStudents(courseId))

    }
    if (type === CourseConstant.GET_AVAILABLE_SLOTS_FAIL) {
      dispatch(alertNotification(error, 'fail'))
    }
    if (type === CourseConstant.GET_REQUEST_COURSE_STUDENT_LIST_SUCCESS) {
      setEntrollStudents(payload?.results)
    }
    if (type === CourseConstant.GET_REQUEST_COURSE_STUDENT_LIST_FAIL) {
      dispatch(alertNotification(error, 'fail'))
    }

    if (type === CourseConstant.ENROLL_STUDENT_SUCCESS) {
      setSelectedStudent(null)
      setSelectedIndex('')
      setShowPopup(false)
      dispatch(getAvailableSlots(courseId))

    }
    if (type === CourseConstant.ENROLL_STUDENT_FAIL) {
      dispatch(alertNotification(error, 'fail'))
    }
  }, [course]);

  const handleClickOpen = (student, index) => {
    setSelectedStudent(student)
    setSelectedIndex(index)
    setShowPopup(true)
  }

  const entrollStudentCall = (e, action) => {
    e.preventDefault()
    dispatch(accpetRejectStudent(selectedStudent?._id, courseId, action, selectedIndex))
  }

  return (
    <>
      <div className="card card-table">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-center mb-0">
              <thead>
                <tr>
                  <th>Time</th>
                  {weeks.map(week => (
                    <th>{week.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {availableTimeSlots.map(row => (
                  <tr>
                    <td>{row.time}</td>
                    {
                      entrollStudents?.cells?.map(cell => (
                        row.id === cell.rowId && (<td><StudentEnrollCell handleClickOpen={handleClickOpen} cell={cell} /></td>)
                      ))
                    }
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>


      <Modal className="modal-modal-dialog-centered" show={showPopup} centered onHide={() => {
        setSelectedIndex('')
        setSelectedStudent(null)
        setShowPopup(false)
      }}>
        <ModalHeader closeButton>Enroll Student Confirmaion</ModalHeader>
        <ModalBody>
          <form>
            <div className="row form-row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input type="text" className="form-control" value={selectedStudent?.firstName} />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input type="text" className="form-control" value={selectedStudent?.lastName} />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Email ID</label>
                  <input type="email" className="form-control" value={selectedStudent?.email} />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Phone Number</label>
                  <input type="text" className="form-control" value={selectedStudent?.phoneNumber} />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Date of Birth</label>
                  <input type="date" className="form-control" value={selectedStudent?.dob} />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Gender</label>
                  <select className="form-control select" value={selectedStudent?.gender}  >
                    <option value="">Select gender</option>
                    <option value="Female">Female</option>
                    <option value="Male">Male</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Grade</label>
                  <input type="text" className="form-control" value={selectedStudent?.grade} />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Payment Account Name</label>
                  <input type="text" className="form-control" value={selectedStudent?.accountName} />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <input onClick={(e) => { entrollStudentCall(e, 'reject') }} type="submit" style={{ backgroundColor: 'red', color: 'white' }} className="form-control" value="Reject" />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <input onClick={(e) => { entrollStudentCall(e, 'accept') }} type="submit" style={{ backgroundColor: 'green', color: 'white' }} className="form-control" value="Accept" />
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  )
}
