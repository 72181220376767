export function authHeader(hasFile = false) {
  let userAccessToken = localStorage.getItem('accessToken');
  if (userAccessToken) {
    if (hasFile) {
      return {
        authorization: 'bearer ' + userAccessToken,
        // Accept: 'application/json',
        AccessControlAllowOrigin: '*',
      };
    } else {
      return {
        authorization: 'bearer ' + userAccessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        AccessControlAllowOrigin: '*',
      };
    }
  } else {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      AccessControlAllowOrigin: '*',
    };
  }
}

export function HandleResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      var callStatus = response.status;
      if (callStatus === 401) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userDetail');
        window.location.href = `${process.env.REACT_APP_LOGOUT_URL}`;
      }
      return { status: callStatus, data: text && JSON.parse(text) };
    }
    const data = text && JSON.parse(text);
    return { status: 200, data: data };
  });
}

export function endPointConnect(url, data, succssType, failType, dispatch) {
  fetch(`${process.env.REACT_APP_API_URL}` + url, data)
    .then(HandleResponse)
    .then((result) => {
      console.log(result);
      const { status, data } = result;
      if (parseInt(status) === 200) {
        dispatch({
          type: succssType,
          payload: data,
          error: data?.message || data.errors,
        });
      } else {
        dispatch({
          type: failType,
          payload: data,
          error: data?.message || data.errors,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: failType,
        payload: '',
        error: error.message,
      });
    });
}

export function authDetail() {
  let user = JSON.parse(localStorage.getItem('userAuthDetail'));
  if (user) {
    return user;
  } else {
    return {
      userId: '',
      userEmailId: '',
      userFirstName: '',
      userLastName: '',
      userType: '',
      userAccessToken: '',
      userRefreshToken: '',
    };
  }
}

export function endPointBaseConnect(url, data, succssType, failType, dispatch) {
  fetch(`${process.env.REACT_APP_API_BASE_URL}` + url, data)
    .then(HandleResponse)
    .then((result) => {
      if (
        result['message'].toUpperCase() === 'SUCCESS' &&
        result.statusCode === 1000
      ) {
        dispatch({
          type: succssType,
          payload: result['data'],
          error: '',
        });
      } else if (result['statusCode'] === '1000') {
        dispatch({
          type: succssType,
          payload: result['data'],
          error: '',
        });
      } else {
        dispatch({
          type: failType,
          payload: '',
          error: result['message'],
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: failType,
        payload: '',
        error: 'Sorry, something went wrong. Please try again later.',
        catchError: error,
      });
    });
}
