export const PaymentConstant = {
    DEFAULTERR: "sorry unable to process! please try again later",

    GET_PAYMENT_DATA_SUCCESS : "GET_PAYMENT_DATA_SUCCESS",
    GET_PAYMENT_DATA_FAIL: "GET_PAYMENT_DATA_FAIL",
    
    SAVE_PAYMENT_DATA_SUCCESS : "SAVE_PAYMENT_DATA_SUCCESS",
    SAVE_PAYMENT_DATA_FAIL: "SAVE_PAYMENT_DATA_FAIL",
    
    ACCEPT_PAYMENT_SUCCESS : "ACCEPT_PAYMENT_SUCCESS",
    ACCEPT_PAYMENT_FAIL: "ACCEPT_PAYMENT_FAIL",
    
    REJECT_PAYMENT_SUCCESS : "REJECT_PAYMENT_SUCCESS",
    REJECT_PAYMENT_FAIL : "REJECT_PAYMENT_FAIL",
}
