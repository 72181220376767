import { KanbanBoardConstant } from "../constant";
const initialState = {
    payload: null,
    type: '',
    error: ""
}

export default function  kanbanBoardReducers(state = initialState, action) {
    switch (action.type) {
        case KanbanBoardConstant.GET_KANBAN_BOARD_DATA_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case KanbanBoardConstant.GET_KANBAN_BOARD_DATA_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case KanbanBoardConstant.SAVE_KANBAN_BOARD_DATA_REQUEST:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error,
            };
        case KanbanBoardConstant.SAVE_KANBAN_BOARD_DATA_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error,
            };
        case KanbanBoardConstant.SAVE_KANBAN_BOARD_DATA_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error,
            };
        
        default:
            return state
    }
}