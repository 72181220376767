import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import VocabSource from './VocabSource';
import AddVocabSource from './AddVocabSource';
import { useSelector } from 'react-redux';
import { VocabConstant } from '../../../redux/constant';
import { alertNotification } from '../../../redux/action';
import { useDispatch } from 'react-redux';
import { getAllVocabSources } from '../../../redux/action/vocabAction';
import AddVocabSourceAudio from './AddVocabSourceAudio';

export default function SingleVocab() {
  const [vocabSources, setVocabSources] = useState([]);
  const { vocabId } = useParams();
  const { state } = useLocation();
  let userDetail = JSON.parse(localStorage.getItem('userDetail'));
  const [vocab, setVocab] = useState(state);
  const [selectedVocabSource, setSelectedVocabSource] = useState(null);
  const [saveVocabSourcePopup, setSaveVocabSourcePopup] = useState(false);
  const [saveVocabSourceAudioPopup, setSaveVocabSourceAudioPopup] =
    useState(false);
  const vocabState = useSelector((state) => state.vocab);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(getAllVocabSources(vocabId));
  }, []);

  useEffect(() => {
    const { type, payload, error } = vocabState;
    console.log(vocabState);
    if (type === VocabConstant.GET_VOCAB_SOURCE_DATA_SUCCESS) {
      setVocabSources(payload?.results);
    }
    if (type === VocabConstant.SAVE_VOCAB_SOURCE_DATA_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
    if (type === VocabConstant.SAVE_VOCAB_SOURCE_DATA_SUCCESS) {
      setSaveVocabSourcePopup(false);
      setLoading(false);
      dispatch(alertNotification(payload.message, 'success'));
      dispatch(getAllVocabSources(vocabId));
      setSelectedVocabSource(null);
    }
    if (type === VocabConstant.SAVE_VOCAB_SOURCE_AUDIO_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
    if (type === VocabConstant.SAVE_VOCAB_SOURCE_AUDIO_SUCCESS) {
      setSaveVocabSourceAudioPopup(false);
      setLoading(false);
      dispatch(alertNotification(payload.message, 'success'));
      dispatch(getAllVocabSources(vocabId));
      setSelectedVocabSource(null);
    }
  }, [vocabState]);

  const toggleVocabSourceAudioModal = (
    vocabSource,
    vocabSourceAudio = null
  ) => {
    setSaveVocabSourceAudioPopup(true);
    setSelectedVocabSource(vocabSource);
  };

  return (
    <>
      { userDetail?.role == 'Teacher' &&
        <><div
        className="mentor-booking"
        style={{ width: '200px', marginBottom: '10px', float: 'right' }}
      >
        <a
          className="btn apt-btn"
          onClick={() => {
            setSaveVocabSourcePopup(true);
            setSelectedVocabSource(null);
          }}
        >
          Create New
        </a>
      </div>
      <AddVocabSource
        selectedVocabSource={selectedVocabSource}
        showPopup={saveVocabSourcePopup}
        onClose={() => {
          setSelectedVocabSource(null);
          setSaveVocabSourcePopup(false);
        }}
        setLoading={setLoading}
        isLoading={isLoading}
        vocabLessonId={vocab?._id}
      />
      <AddVocabSourceAudio
        showPopup={saveVocabSourceAudioPopup}
        onClose={() => setSaveVocabSourceAudioPopup(false)}
        vocabSourceId={selectedVocabSource?._id}
        setLoading={setLoading}
        isLoading={isLoading}
      /></>
      }
      <div className="d-flex align-items-center justify-content-center p-4">
        <h2>{vocab?.name}</h2>
      </div>
      <div className="row">
        {vocabSources.map((vsource) => (
          <VocabSource
            data={vsource}
            toggleModal={toggleVocabSourceAudioModal}
            role = {userDetail?.role}
          />
        ))}
      </div>
    </>
  );
}
