import React, { useEffect } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import sample from '../../../assets/mp3/sample.mp3';
import { Tab, Tabs } from 'react-bootstrap';
export default function VocabSource({ key, data, toggleModal,role }) {
  // useEffect(() => {
  //   console.log(data);
  // }, [data]);
  return (
    <div className="col-xs-12 col-md-6 col-xl-4">
      <div className="course-box blog grid-blog">
        <div className="blog-image mb-0">
          <img
            className="img-fluid"
            src={data.imagePath}
            alt="Vocab Image"
            style={{ height: '250px' }}
          />
        </div>
        <div className="course-content">
          {role == 'Teacher' && <div className="row">
            <div className="col text-right">
              {/* <Link className="text-success">
                <i className="far fa-edit" /> Edit
              </Link> */}
              <a
                className="btn apt-btn btn-primary text-light"
                onClick={() => {
                  toggleModal(data);
                }}
              >
                Add
              </a>
            </div>
          </div>}
          <div
            className="mentor-booking"
            style={{ width: '100px', marginBottom: '10px', float: 'right' }}
          ></div>

          <Tabs
            defaultActiveKey="0"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            {data.audioSources.map((audioSource, index) => (
              <Tab eventKey={index} title={audioSource.language}>
                <span className="course-title">{audioSource.text}</span>
                <AudioPlayer
                  autoPlay={false}
                  src={audioSource.audioPath}
                  onPlay={(e) => console.log('onPlay')}
                  // other props here
                />
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
}
