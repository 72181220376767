import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { alertNotification, getLessons } from '../../redux/action';
import { CourseConstant } from '../../redux/constant';
import LessonModal from './LessonModal';

const LessonList = ({ courseId }) => {
  const [lessons, setLessons] = useState([]);
  const [saveLessonPopup, setSaveLessonPopup] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const course = useSelector((state) => state.course);
  let userDetail = JSON.parse(localStorage.getItem('userDetail'));

  useEffect(() => {
    dispatch(getLessons(courseId));
  }, []);

  useEffect(() => {
    const { type, payload, error } = course;
    if (type === CourseConstant.GET_LESSON_LIST_SUCCESS) {
      setLessons(payload?.results);
    }
    if (type === CourseConstant.GET_LESSON_LIST_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
    if (type === CourseConstant.SAVE_LESSON_DATA_SUCCESS) {
      setSelectedLesson(null);
      setSaveLessonPopup(false);
      dispatch(alertNotification(error, 'success'));
      dispatch(getLessons(courseId));
    }
    if (type === CourseConstant.SAVE_LESSON_DATA_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
  }, [course]);

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
          result[3],
          16
        )},0.5)`
      : null;
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12 col-12">
          <div
            className="mentor-booking"
            style={{ width: '200px', marginBottom: '10px', float: 'right' }}
          >
            <a
              className="btn apt-btn"
              onClick={() => {
                setSaveLessonPopup(true);
              }}
            >
              Create Lesson
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="row row-grid">
          {lessons?.map((lesson, i) => {
            const { _id, name, description, color } = lesson;
            return (
              <div className="col-md-6 col-lg-4 col-xl-3">
                <div
                  className="profile-widget"
                  style={{ backgroundColor: hexToRgb(color) }}
                >
                  <div className="pro-content">
                    <h3 className="title">
                      <Link> {name}</Link>
                      <i className="fas fa-check-circle verified" />
                    </h3>
                    <p className="speciality">{description}</p>
                    <div className="row row-sm">
                      <div className="mb-2">
                        <Link
                          to={`/mentor/tasks/${_id}`}
                          className="btn view-btn"
                        >
                          View Tasks
                        </Link>
                      </div>
                      <div className="">
                        <Link
                          onClick={() => {
                            setSelectedLesson(lesson);
                            setSaveLessonPopup(true);
                          }}
                          className="btn book-btn"
                        >
                          Edit
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <LessonModal
        courseId={courseId}
        selectedLesson={selectedLesson}
        showPopup={saveLessonPopup}
        onClose={() => {
          setSelectedLesson(null);
          setSaveLessonPopup(false);
        }}
      />
    </>
  );
};

export default LessonList;
