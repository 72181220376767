import { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { alertNotification, getCourseStudents } from '../../redux/action';
import { CourseConstant } from '../../redux/constant';
import PaymentCard from '../paymentcard';

const CourseStudentList = ({ courseId }) => {
  const [courseStudents, setCourseStudents] = useState([]);
  const [showPaymentCard, setShowPaymentCard] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const course = useSelector((state) => state.course);
    let userDetail = JSON.parse(localStorage.getItem('userDetail'))

  useEffect(() => {
    dispatch(getCourseStudents(courseId));
  }, []);

  useEffect(() => {
    const { type, payload, error } = course;
    if (type === CourseConstant.GET_COURSE_STUDENT_LIST_SUCCESS) {
      setCourseStudents(payload?.result);
    }
    if (type === CourseConstant.GET_COURSE_STUDENT_LIST_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
    if (type === CourseConstant.ENROLL_STUDENT_SUCCESS) {
      dispatch(getCourseStudents(courseId));
    }
  }, [course]);

  return (
    <>
      <div className="row">
        <div className="col-md-12 col-12">
          <div className="card card-table">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Gender</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {courseStudents?.map((courseStudent, i) => {
                      const { _id, name, firstName, lastName, email, gender } =
                        courseStudent;
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>
                            {firstName} {lastName}
                          </td>
                          <td>{email}</td>
                          <td>{gender}</td>
                          <td>
                            <a
                              onClick={() => {
                                setSelectedStudent(courseStudent);
                                setShowPaymentCard(true);
                              }}
                              className="btn btn-sm bg-primary-light m-2"
                            >
                              View Payment Card
                            </a>
                            <Link
                              to={`/mentor/course/${courseId}/students/${_id}`}
                              className="btn btn-sm bg-primary-light m-2"
                            >
                              View Progress
                            </Link>
                            <Link
                              to={`/mentor/course/${courseId}/students/${_id}/home-work`}
                              className="btn btn-sm bg-primary-light m-2"
                            >
                              View Homework
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal-modal-dialog-centered"
        show={showPaymentCard}
        centered
        onHide={() => {
          setSelectedStudent(null);
          setShowPaymentCard(false);
        }}
      >
        <ModalHeader closeButton>
          Payment card {selectedStudent?.firstName}
        </ModalHeader>
        <ModalBody>
          <PaymentCard id={selectedStudent?._id} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default CourseStudentList;
