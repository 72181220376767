import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { saveQuestion } from '../../redux/action/forumActions';

const SaveQuestionModal = ({ showPopup, selectedQuestion, onClose, courseId }) => {
    const dispatch = useDispatch();
    const [content, setContent] = useState({});
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (showPopup) {
            if (selectedQuestion) {
                reset(selectedQuestion);
                setContent(selectedQuestion?.content);
            } else {
                reset({ title: '', _id: '', courseId: courseId });
                setContent({});
            }
        }
    }, [showPopup]);

    const onSaveTask = (data) => {
        const { title, _id } = data;
        dispatch(saveQuestion(selectedQuestion ? 'edit' : 'create', { title, content, courseId, questionId: _id }));
    };

    return (
        <Modal
            size="xl"
            fullscreen={false}
            className="modal-modal-dialog-centered"
            show={showPopup}
            centered
            onHide={() => {
                setContent({})
                onClose()
            }}
        >
            <ModalHeader closeButton>
                {!selectedQuestion ? 'Ask Question' : 'Edit Question'}
            </ModalHeader>
            <ModalBody>
                <form>
                    <div className="hours-info">
                        <div className="row form-row hours-cont">
                            <div className="col-12">
                                <div className="row form-row">
                                    <div className="col-12 col-md-12">
                                        <div className="form-group">
                                            <label>Question</label>
                                            <input
                                                type="text"
                                                name="questionTitle"
                                                className="form-control"
                                                {...register('title', { required: true })}
                                                placeholder="Enter Question"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={content ? content : {}}
                                                onReady={(editor) => {
                                                    // You can store the "editor" and use when it is needed.
                                                    console.log('Editor is ready to use!', editor);
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setContent(data);
                                                    console.log({ event, editor, data });
                                                }}
                                                onBlur={(event, editor) => {
                                                    console.log('Blur.', editor);
                                                }}
                                                onFocus={(event, editor) => {
                                                    console.log('Focus.', editor);
                                                }}
                                                style={{ height: '600px' }}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="submit-section text-center">
                        <button
                            type="submit"
                            onClick={handleSubmit(onSaveTask)}
                            className="btn btn-primary submit-btn"
                        >
                            Save Changes
                        </button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};
export default SaveQuestionModal;
