export const CourseConstant = {
    DEFAULTERR: "sorry unable to process! please try again later",

    GET_COURSE_DATA_SUCCESS : "GET_COURSE_DATA_SUCCESS",
    GET_COURSE_DATA_FAIL: "GET_COURSE_DATA_FAIL",
    
    SAVE_COURSE_DATA_SUCCESS : "SAVE_COURSE_DATA_SUCCESS",
    SAVE_COURSE_DATA_FAIL: "SAVE_COURSE_DATA_FAIL",

    GET_LESSON_LIST_SUCCESS : "GET_LESSON_LIST_SUCCESS",
    GET_LESSON_LIST_FAIL: "GET_LESSON_LIST_FAIL",

    SAVE_LESSON_DATA_SUCCESS : "SAVE_LESSON_DATA_SUCCESS",
    SAVE_LESSON_DATA_FAIL: "SAVE_LESSON_DATA_FAIL",

    GET_TASK_LIST_SUCCESS : "GET_TASK_LIST_SUCCESS",
    GET_TASK_LIST_FAIL: "GET_TASK_LIST_FAIL",

    SAVE_TASK_DATA_SUCCESS : "SAVE_TASK_DATA_SUCCESS",
    SAVE_TASK_DATA_FAIL: "SAVE_TASK_DATA_FAIL",

    GET_COURSE_STUDENT_LIST_SUCCESS : "GET_COURSE_STUDENT_LIST_SUCCESS",
    GET_COURSE_STUDENT_LIST_FAIL: "GET_COURSE_STUDENT_LIST_FAIL",

    GET_AVAILABLE_SLOTS_SUCCESS : "GET_AVAILABLE_SLOTS_SUCCESS",
    GET_AVAILABLE_SLOTS_FAIL: "GET_AVAILABLE_SLOTS_FAIL",
    
    SAVE_AVAILABLE_SLOTS_SUCCESS : "SAVE_AVAILABLE_SLOTS_SUCCESS",
    SAVE_AVAILABLE_SLOTS_FAIL: "SAVE_AVAILABLE_SLOTS_FAIL",

    GET_ENTROLL_COURSE_SLOTS_SUCCESS : "GET_ENTROLL_COURSE_SLOTS_SUCCESS",
    GET_ENTROLL_COURSE_SLOTS_FAIL: "GET_ENTROLL_COURSE_SLOTS_FAIL",

    GET_REQUEST_COURSE_STUDENT_LIST_SUCCESS : "GET_REQUEST_COURSE_STUDENT_LIST_SUCCESS",
    GET_REQUEST_COURSE_STUDENT_LIST_FAIL: "GET_REQUEST_COURSE_STUDENT_LIST_FAIL",

    GET_STUDENTS_LIST_SUCCESS : "GET_STUDENTS_LIST_SUCCESS",
    GET_STUDENTS_LIST_FAIL: "GET_STUDENTS_LIST_FAIL",

    GET_MENTOR_LIST_SUCCESS : "GET_MENTOR_LIST_SUCCESS",
    GET_MENTOR_LIST_FAIL: "GET_MENTOR_LIST_FAIL",

    ENROLL_STUDENT_SUCCESS : "ENROLL_STUDENT_SUCCESS",
    ENROLL_STUDENT_FAIL: "ENROLL_STUDENT_FAIL",

    ENROLL_COURSE_SUCCESS : "ENROLL_COURSE_SUCCESS",
    ENROLL_COURSE_FAIL: "ENROLL_COURSE_FAIL",

}
