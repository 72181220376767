export const ForumConstant = {
    DEFAULTERR: "sorry unable to process! please try again later",

    GET_QUESTION_LIST_SUCCESS : "GET_QUESTION_LIST_SUCCESS",
    GET_QUESTION_LIST_FAIL: "GET_QUESTION_LIST_FAIL",
    
    GET_QUESTION_SUCCESS : "GET_QUESTION_SUCCESS",
    GET_QUESTION_FAIL: "GET_QUESTION_FAIL",
    
    SAVE_QUESTION_SUCCESS : "SAVE_QUESTION_SUCCESS",
    SAVE_QUESTION_FAIL: "SAVE_QUESTION_FAIL",
    
    SAVE_ANSWER_SUCCESS : "SAVE_ANSWER_SUCCESS",
    SAVE_ANSWER_FAIL : "SAVE_ANSWER_FAIL",

    DELETE_QUESTION_SUCCESS : "DELETE_QUESTION_SUCCESS",
    DELETE_QUESTION_FAIL: "DELETE_QUESTION_FAIL",
    
    DELETE_ANSWER_SUCCESS : "DELETE_ANSWER_SUCCESS",
    DELETE_ANSWER_FAIL : "DELETE_ANSWER_FAIL",
}
