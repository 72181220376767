import { TimeslotConstant } from "../constant";
const initialState = {
    payload: "",
    type: '',
    error: ""
}

export default function timeslotReducers(state = initialState, action) {
    switch (action.type) {
        case TimeslotConstant.GET_TIMESLOT_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case TimeslotConstant.GET_TIMESLOT_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case TimeslotConstant.SAVE_TIMESLOT_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case TimeslotConstant.SAVE_TIMESLOT_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case TimeslotConstant.DELETE_TIMESLOT_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case TimeslotConstant.DELETE_TIMESLOT_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        default:
            return state
    }
}