import { ForumConstant } from "../constant";
const initialState = {
    questions: [],
    selectedQuestion: null,
    type: '',
    error: ""
}

export default function forumReducers(state = initialState, action) {
    switch (action.type) {
        case ForumConstant.GET_QUESTION_LIST_SUCCESS:
            return {
                questions: action.payload
            };
        case ForumConstant.GET_QUESTION_LIST_FAIL:
            return {
                questions: [],
                type: action.type,
                error: action.error
            };
        case ForumConstant.SAVE_QUESTION_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case ForumConstant.SAVE_QUESTION_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case ForumConstant.GET_QUESTION_SUCCESS:
            return {
                selectedQuestion: action.payload
            };
        case ForumConstant.GET_QUESTION_FAIL:
            return {
                selectedQuestion: null,
                type: action.type,
                error: action.error
            };
        case ForumConstant.SAVE_ANSWER_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case ForumConstant.SAVE_ANSWER_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case ForumConstant.DELETE_QUESTION_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case ForumConstant.DELETE_QUESTION_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case ForumConstant.DELETE_ANSWER_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case ForumConstant.DELETE_ANSWER_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        default:
            return state
    }
}