export const AuthConstant = {
    DEFAULTERR: "sorry unable to process! please try again later",

    LOGINSUCCESS: "LOGIN_SUCCESS",
    LOGINFAIL: "LOGIN_FAIL",

    REGISTERSUCCESS: "REGISTER_SUCCESS",
    REGISTERFAIL: "REGISTER_FAIL",

    VERIFYSUCCESS: "VERIFY_SUCCESS",
    VERIFYFAIL: "VERIFY_FAIL",

    FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
    FORGOT_PASSWORD_FAIL: "FORGOT_PASSWORD_FAIL",
    
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",
}
