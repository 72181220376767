import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { decodeJwt } from '../../constant/jwt';
import { alertNotification, login } from '../../redux/action';
import { AuthConstant } from '../../redux/constant';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [submitLoader, setSubmitLoader] = useState(false);
  const [submit, setSubmit] = useState(false);

  useEffect(()=>{
    if(localStorage.getItem('accessToken')){
      let userDetail = JSON.parse(localStorage.getItem('userDetail'));
      const { role} = userDetail;
      
        if (role === 'Student') {
          navigate('/student/courses');
        }
        if (role === 'Teacher') {
          navigate('/mentor/courses');
        }
    }
  },[])

  const onSubmit = (data) => {
    setSubmit(true);
    const { email, password } = data;
    if (email && password) {
      setSubmitLoader(true);
      dispatch(
        login({
          email: email ? email : '',
          password: password ? password : '',
          googleSigninId: null,
        })
      );
    }
  };

  useEffect(() => {
    const { type, payload, error } = auth;
    if (type === AuthConstant.LOGINSUCCESS) {
      setSubmitLoader(false);
      setSubmit(false);
      if (payload) {
        const { results, token } = payload;
        const { role } = results;
        localStorage.setItem('accessToken', token);
        localStorage.setItem('userDetail', JSON.stringify(results));
        dispatch(alertNotification('Login successful', 'success'));
        if (role === 'Student') {
          navigate('/student/courses');
        }
        if (role === 'Teacher') {
          navigate('/mentor/courses');
        }
      }
    }

    if (type === AuthConstant.LOGINFAIL) {
      dispatch(alertNotification(error, 'fail'));
      setSubmitLoader(false);
      setSubmit(false);
    }
  }, [auth]);

  const responseMessage = (response) => {
    console.log(response);
    let payload = decodeJwt(response.credential);
    let googleId = payload.sub;
    const { email } = payload;
    console.log({ googleId, email });
    dispatch(
      login({
        email: email ? email : '',
        password: '',
        googleSigninId: googleId,
      })
    );
  };
  const errorMessage = (error) => {
    dispatch(alertNotification('Google Login fail', 'fail'));
  };

  return (
    <div className="bg-pattern-style">
      <div className="content">
        <div className="account-content">
          <div className="account-box">
            <div className="login-right">
              <div className="login-header">
                <h3>
                  Login <span>Genius Mentors</span>
                </h3>
                <p className="text-muted">Access to our dashboard</p>
              </div>
              <form className="needs-validation" noValidate>
                <div className="form-group">
                  <label className="form-control-label">Email Address</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    required
                    {...register('email', {
                      required: true,
                    })}
                  />
                  {errors.email && (
                    <div
                      className="invalid-feedback"
                      style={{ display: 'block' }}
                    >
                      Please provide valid email
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-control-label">Password</label>
                  <div className="pass-group">
                    <input
                      type="password"
                      name="password"
                      className="form-control pass-input"
                      required
                      {...register('password', {
                        required: true,
                        minLength: 8,
                      })}
                    />
                    {errors.password && (
                      <div
                        className="invalid-feedback"
                        style={{ display: 'block' }}
                      >
                        Please provide valid password
                      </div>
                    )}
                  </div>
                </div>
                <div className="text-right">
                  <Link className="forgot-link" to="/forgot-password">
                    Forgot Password ?
                  </Link>
                </div>
                <button
                  className="btn btn-primary login-btn"
                  type="submit"
                  disabled={submitLoader}
                  onClick={handleSubmit(onSubmit)}
                >
                  {submitLoader ? (
                    <i className="fa fa-spinner fa-spin" />
                  ) : (
                    'Login'
                  )}
                </button>
                <div className="text-center dont-have">
                  Don’t have an account? <Link to="/register">Register</Link>
                </div>
              </form>
              <div className="login-or">
                <span className="or-line" />
                <span className="span-or">OR</span>
              </div>
              {/* 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 */}
              <div style={{ marginLeft: '30%' }}>
                <GoogleOAuthProvider clientId="798204089168-64ridcqt0ellfbun6kt3g8936ng131eh.apps.googleusercontent.com">
                  <GoogleLogin
                    onSuccess={responseMessage}
                    onError={errorMessage}
                  />
                </GoogleOAuthProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
