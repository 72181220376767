import { PaymentConstant } from "../constant";
const initialState = {
    payload: "",
    type: '',
    error: ""
}

export default function paymentReducers(state = initialState, action) {
    switch (action.type) {
        case PaymentConstant.GET_PAYMENT_DATA_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case PaymentConstant.GET_PAYMENT_DATA_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case PaymentConstant.SAVE_PAYMENT_DATA_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case PaymentConstant.SAVE_PAYMENT_DATA_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case PaymentConstant.ACCEPT_PAYMENT_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case PaymentConstant.ACCEPT_PAYMENT_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case PaymentConstant.REJECT_PAYMENT_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case PaymentConstant.REJECT_PAYMENT_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        default:
            return state
    }
}