import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from '../components/layout/header';
import MentorSideBar from '../components/layout/mentorSidebar';
import Dashboard from '../pages/dashboard';
import CourseList from './course';
import CourseForm from './course/CourseForm';
import SingleCourse from './course/SingleCourse';
import LessonList from './course/LessonList';
import TaskList from './course/task';
import CourseListForForum from './forum';
import QuestionList from './forum/questions';
import SingleQuestion from './forum/singleQuestion';
import Payment from './payment';
import Profile from './profile';
import TimeSlots from './timePeriods';
import TodoPage from './todo/Index';
import HomeworkPage from './homework-board';
import SingleVocab from './course/vocabs/SingleVocab';
import CourseVocabs from './course/vocabs/CourseVocabs';

function MentorRoute() {
  if (localStorage.getItem('userDetail')) {
    let userRole = JSON.parse(localStorage.getItem('userDetail'))?.role;

    if (userRole !== 'Teacher') {
      window.location.href = `${process.env.REACT_APP_LOGOUT_URL}`;
    }
  } else {
    window.location.href = `${process.env.REACT_APP_LOGOUT_URL}`;
  }

  return (
    <>
      <Header />
      <div>
        {/* <div className="breadcrumb-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/app/index">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Profile Settings</li>
                  </ol>
                </nav>
                <h2 className="breadcrumb-title">Profile Settings</h2>
              </div>
            </div>
          </div>
        </div> */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3">
                <MentorSideBar />
              </div>
              <div className="col-md-7 col-lg-8 col-xl-9">
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/payment" element={<Payment />} />
                  <Route path="/time-slots" element={<TimeSlots />} />
                  <Route path="/courses" element={<CourseList />} />
                  <Route path="/lessons/:id" element={<LessonList />} />
                  <Route path="/tasks/:id" element={<TaskList />} />
                  <Route path="/forum" element={<CourseListForForum />} />
                  <Route
                    path="/questions/:courseId"
                    element={<QuestionList />}
                  />
                  <Route
                    path="/question/:questionId"
                    element={<SingleQuestion />}
                  />
                  <Route path="/course/:id" element={<SingleCourse />} />
                  <Route path="/course/:id/edit" element={<CourseForm />} />
                  <Route path="/course/create" element={<CourseForm />} />
                  <Route
                    path="/course/:courseId/students/:studentId"
                    element={<TodoPage />}
                  />
                  <Route
                    path="/course/:courseId/students/:studentId/home-work"
                    element={<HomeworkPage />}
                  />
                  <Route
                    path="/course/:courseId/vocab-sources/:vocabId"
                    element={<SingleVocab />}
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MentorRoute;
