import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { alertNotification, getTasks } from '../../redux/action';
import { CourseConstant } from '../../redux/constant';
import TaskModal from './TaskModal';
import SaveTaskModal from './saveTask';
const TaskList = () => {
  const [tasks, setTasks] = useState([]);
  const [saveTaskPopup, setSaveTaskPopup] = useState(false);
  const [viewTaskPopup, setViewTaskPopup] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const course = useSelector((state) => state.course);
  let userDetail = JSON.parse(localStorage.getItem('userDetail'));

  useEffect(() => {
    dispatch(getTasks(id));
  }, []);

  useEffect(() => {
    const { type, payload, error } = course;
    if (type === CourseConstant.GET_TASK_LIST_SUCCESS) {
      console.log('payload?.results', payload?.results);
      setTasks(payload?.results?.tasks);
    }
    if (type === CourseConstant.GET_TASK_LIST_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
    if (type === CourseConstant.SAVE_TASK_DATA_SUCCESS) {
      dispatch(alertNotification(error, 'success'));
      setSelectedTask(null);
      setSaveTaskPopup(false);
      dispatch(getTasks(id));
    }
    if (type === CourseConstant.SAVE_TASK_DATA_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
  }, [course]);
  return (
    <>
      <div className="row">
        <div className="col-md-12 col-12">
          <div
            className="mentor-booking"
            style={{ width: '200px', marginBottom: '10px', float: 'right' }}
          >
            <a
              className="btn apt-btn"
              onClick={() => {
                setSaveTaskPopup(true);
              }}
            >
              Create Task
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-12">
          <div className="card card-table">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Type</th>
                      <th>Actions</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {tasks?.map((task, i) => {
                      const { _id, title, content, type, color } = task;
                      return (
                        <tr>
                          <td>{title}</td>
                          <td>{type}</td>
                          {/* <td><div dangerouslySetInnerHTML={{ __html: content }} /></td> */}
                          <td>
                            {/* <a className="btn btn-sm bg-success-light m-2" title="View Task">
                                                                <i className="fas fa-eye" />
                                                            </a> */}
                            <span
                              className="btn btn-sm bg-primary-light m-2"
                              title="Edit Task"
                              onClick={() => {
                                setSelectedTask(task);
                                setSaveTaskPopup(true);
                              }}
                            >
                              <i className="fas fa-pen" />
                            </span>
                            <span
                              className="btn btn-sm bg-primary-light m-2"
                              title="View Task"
                              onClick={() => {
                                setSelectedTask(task);
                                setViewTaskPopup(true);
                              }}
                            >
                              <i className="fas fa-eye" />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SaveTaskModal
        lessonId={id}
        selectedTask={selectedTask}
        showPopup={saveTaskPopup}
        onClose={() => {
          setSelectedTask(null);
          setSaveTaskPopup(false);
        }}
      />
      <TaskModal
        selectedTask={selectedTask}
        showPopup={viewTaskPopup}
        onClose={() => {
          setSelectedTask(null);
          setViewTaskPopup(false);
        }}
      />
    </>
  );
};

export default TaskList;
