import {
    authHeader,
    endPointConnect
} from "../../utils/authHelper";
import { TimeslotConstant } from "../constant";
  
  export const getTimeslots = (id) => (dispatch) => {
    try {
      const requestData = {
        method: "GET",
        headers: authHeader()
      };
      endPointConnect(
        "teacher/timeslots/"+id,
        requestData,
        TimeslotConstant.GET_TIMESLOT_SUCCESS,
        TimeslotConstant.GET_TIMESLOT_FAIL,
        dispatch
      );
    } catch (err) {
      dispatch({
        type: TimeslotConstant.GET_TIMESLOT_FAIL,
        payload: "",
        error: TimeslotConstant.DEFAULTERR,
      });
    }
  };

  export const saveTimeSlot = (data) => (dispatch) => {
    try {
      const requestData = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
      };
      endPointConnect(
        "teacher/timeslots",
        requestData,
        TimeslotConstant.SAVE_TIMESLOT_SUCCESS,
        TimeslotConstant.SAVE_TIMESLOT_FAIL,
        dispatch
      );
    } catch (err) {
      dispatch({
        type: TimeslotConstant.SAVE_TIMESLOT_FAIL,
        payload: "",
        error: TimeslotConstant.DEFAULTERR,
      });
    }
  };
  
  export const deleteTimeslot = (id) => (dispatch) => {
    try {
      const requestData = {
        method: "DELETE",
        headers: authHeader()
      };
      endPointConnect(
        "teacher/timeslots/"+id,
        requestData,
        TimeslotConstant.DELETE_TIMESLOT_SUCCESS,
        TimeslotConstant.DELETE_TIMESLOT_FAIL,
        dispatch
      );
    } catch (err) {
      dispatch({
        type: TimeslotConstant.DELETE_TIMESLOT_FAIL,
        payload: "",
        error: TimeslotConstant.DEFAULTERR,
      });
    }
  };