import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingGif } from '../../constant/imagePaths';
import { activateAccount, alertNotification } from '../../redux/action';
import { AuthConstant } from '../../redux/constant';

const Activate = () => {
  const { key } = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);


  const [submitLoader, setSubmitLoader] = useState(false);


  useEffect(() => {
    setSubmitLoader(true)
    dispatch(
      activateAccount(key)
    );
  }, [])

  useEffect(() => {
    const { type, payload, error } = auth;
    if (type === AuthConstant.VERIFYSUCCESS) {
      setSubmitLoader(false);
      navigate('/login')
    }

    if (type === AuthConstant.LOGINFAIL) {
      dispatch(alertNotification(error, 'fail'));
      setSubmitLoader(false);
    }
  }, [auth]);

  return (
    <div className="bg-pattern-style">
      <div className="content">
        <div className="account-content">
          <div className="account-box">
            <div className="login-right">
              <div className="login-header">
                <h3>
                  <span>Account Activation In Progress</span>
                </h3>
                <p className="text-muted">Please wait until activate</p>
              </div>
              <div className="login-body" style={{ justifyContent: 'center', display: 'flex' }}>
                {
                  submitLoader &&
                  <img src={LoadingGif} alt='Loading' height='200px' width='200px' />
                }
                {
                  !submitLoader &&
                  <h3 style={{ color: 'red' }}>Activation Failed</h3>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activate;
