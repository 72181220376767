import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { alertNotification, forgotPassword } from '../../redux/action';
import { AuthConstant } from '../../redux/constant';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [submitLoader, setSubmitLoader] = useState(false);
  const [submit, setSubmit] = useState(false);

  const onSubmit = (data) => {
    setSubmit(true);
    const { email } = data;
    if (email) {
      setSubmitLoader(true);
      dispatch(
        forgotPassword({
          email: email ? email : ''
        })
      );
    }
  };

  useEffect(() => {
    const { type, payload, error } = auth;
    if (type === AuthConstant.FORGOT_PASSWORD_SUCCESS) {
      setSubmitLoader(false);
      setSubmit(false);
      if (payload?.status === "success") {
        dispatch(alertNotification(error, 'success'));

      } else {
        dispatch(alertNotification('Forgot password failed', 'fail'));

      }

    }

    if (type === AuthConstant.FORGOT_PASSWORD_FAIL) {
      dispatch(alertNotification('Forgot password failed', 'fail'));
      setSubmitLoader(false);
      setSubmit(false);
    }
  }, [auth]);
  return (
    <div className="bg-pattern-style">
      <div className="content">
        <div className="account-content">
          <div className="account-box">
            <div className="login-right">
              <div className="login-header">
                <h3>Forgot Password?</h3>
                <p className="text-muted">Enter your email to get a password reset link</p>
              </div>
              <form >
                <div className="form-group">
                  <label className="form-control-label">Email Address</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    required
                    {...register('email', {
                      required: true,
                    })}
                  />
                  {errors.email && (
                    <div
                      className="invalid-feedback"
                      style={{ display: 'block' }}
                    >
                      Please provide valid email
                    </div>
                  )}
                </div>
                <div className="text-right">
                  <Link className="forgot-link" to="/login">Remember your password?</Link>
                </div>
                <Link className="btn btn-primary login-btn" disabled={submitLoader}
                  onClick={handleSubmit(onSubmit)}>             {submitLoader ? (
                    <i className="fa fa-spinner fa-spin" />
                  ) : (
                    'Reset Password'
                  )}</Link>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
