import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveTimeSlot } from "../../redux/action";

const SaveTimeslot = ({ showPopup, onClose }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const timeslot = useSelector((state) => state.timeslot);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const [submit, setSubmit] = useState(false);

    let userDetail = JSON.parse(localStorage.getItem('userDetail'))


    const onSubmit = (data) => {
        setSubmit(true);
        dispatch(saveTimeSlot(data))
    };

    useEffect(() => {
        if (!showPopup) {
            reset({
                from: '',
                to: '',
            })

        }

    }, [showPopup]);

    return (
        <Modal className="modal-modal-dialog-centered" show={showPopup} centered onHide={onClose}>
            <ModalHeader closeButton> New Timeslot</ModalHeader>
            <ModalBody>
                <form>
                    <div className="row form-row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>From</label>
                                <input type="time" className="form-control" {...register("from", { required: true })} />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>To</label>
                                <input type="time" className="form-control" {...register("to", { required: true })} />
                            </div>
                        </div>
                    </div>

                    <div className="submit-section">
                        <button onClick={handleSubmit(onSubmit)} className="btn btn-primary submit-btn">Save Timeslot</button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default SaveTimeslot;