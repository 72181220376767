import moment from 'moment';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';

export default function TaskModal({ showPopup, selectedTask, onClose, type }) {
  return (
    <Modal
      className="modal-modal-dialog-centered"
      show={showPopup}
      centered
      size="lg"
      onHide={onClose}
    >
      <ModalHeader closeButton> Task Details </ModalHeader>
      <ModalBody>
        <div className="pb-2">
          <div>Title:</div>
          <div>{selectedTask?.title}</div>
        </div>
        {type === 'Homework' && (
          <div className="pb-2">
            <div>Due date:</div>
            <div>{moment(selectedTask?.dueDate).format('YYYY-MM-DD')}</div>
          </div>
        )}
        <div className="pb-2">
          <div>Description:</div>
          <div dangerouslySetInnerHTML={{ __html: selectedTask?.content }} />
        </div>
      </ModalBody>
    </Modal>
  );
}
