import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { alertNotification, getProfileData, saveProfile } from "../../redux/action";
import { ProfileConstant } from "../../redux/constant";


const Profile = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const profile = useSelector((state) => state.profile);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const [submitLoader, setSubmitLoader] = useState(false);
    const [submit, setSubmit] = useState(false);


    const onSubmit = (data) => {
        setSubmit(true);
        setSubmitLoader(true)
        dispatch(saveProfile(data))
    };

    useEffect(() => {
        const { type, payload, error } = profile;
        if (type === ProfileConstant.GET_PROFILE_DATA_SUCCESS) {
            reset(payload?.results)
        }
        if (type === ProfileConstant.GET_PROFILE_DATA_FAIL) {
            dispatch(alertNotification(error, 'fail'))
        }
        if (type === ProfileConstant.SAVE_PROFILE_DATA_SUCCESS) {
            dispatch(alertNotification(error, 'success'))
            setSubmitLoader(false)
            fetchProfile()
        }
        if (type === ProfileConstant.SAVE_PROFILE_DATA_FAIL) {
            setSubmitLoader(false)
            dispatch(alertNotification(error, 'fail'))
        }
    }, [profile]);

    useEffect(() => {
        fetchProfile()
    }, []);

    const fetchProfile = () => {
        let userDetail = JSON.parse(localStorage.getItem('userDetail'))
        dispatch(getProfileData(userDetail?._id))
    }
    return (
        <div className="card">
            <div className="card-body">
                <form>
                    <div className="row form-row">
                        {/* <div className="col-12 col-md-12">
                            <div className="form-group">
                                <div className="change-avatar">
                                    <div className="profile-img">
                                        <img src={USER} alt="User Image" />
                                    </div>
                                    <div className="upload-img">
                                        <div className="change-photo-btn">
                                            <span><i className="fa fa-upload" /> Upload Photo</span>
                                            <input type="file" className="upload" />
                                        </div>
                                        <small className="form-text text-muted">Allowed JPG, GIF or PNG. Max size of 2MB</small>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>First Name</label>
                                <input type="text" className="form-control"  {...register("firstName", { required: true })} />
                                {
                                    errors.firstName && <span>Please enter first name</span>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Last Name</label>
                                <input type="text" className="form-control"  {...register("lastName", { required: true })} />
                                {
                                    errors.lastName && <span style={{ color: 'red' }}>Please enter last name</span>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Email ID</label>
                                <input type="email" className="form-control"  {...register("email", { required: true })} />
                                {
                                    errors.email && <span style={{ color: 'red' }}>Please enter email</span>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Phone Number</label>
                                <input type="text" className="form-control"  {...register("phoneNumber", { required: true })} />
                                {
                                    errors.phoneNumber && <span style={{ color: 'red' }}>Please enter phone number</span>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Date of Birth</label>
                                <input type="date" className="form-control" {...register("dob", { required: true })} />
                                {
                                    errors.dob && <span style={{ color: 'red' }}>Please enter date of birth</span>
                                }
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Gender</label>
                                <select className="form-control select"

                                    {...register("gender", {
                                        required: true
                                    })}
                                >
                                    <option value="">Select gender</option>
                                    <option value="Female">Female</option>
                                    <option value="Male">Male</option>
                                </select>
                                {
                                    errors.gender && <span style={{ color: 'red' }}>Please select gender</span>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Grade</label>
                                <input type="text" className="form-control" {...register("grade", { required: true })} />
                                {
                                    errors.grade && <span style={{ color: 'red' }}>Please enter grade</span>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Payment Account Name</label>
                                <input type="text" className="form-control" {...register("accountName", { required: true })} />
                                {
                                    errors.accountName && <span style={{ color: 'red' }}>Please enter account name</span>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Father's Name</label>
                                <input type="text" className="form-control" {...register("fatherName", { required: true })} />
                                {
                                    errors.fatherName && <span style={{ color: 'red' }}>Please enter father name</span>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Mother's Name</label>
                                <input type="text" className="form-control" {...register("motherName", { required: true })} />
                                {
                                    errors.motherName && <span style={{ color: 'red' }}>Please enter mother name</span>
                                }
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label>Interests</label>
                                <input type="text" className="form-control" {...register("interests", { required: false })} />
                            </div>
                        </div>
                    </div>

                    <div className="submit-section">
                        <button disabled={submitLoader} onClick={handleSubmit(onSubmit)} className="btn btn-primary submit-btn">
                            {submitLoader ? (
                                <i className="fa fa-spinner fa-spin" />
                            ) : (
                                'Save Changes'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Profile;