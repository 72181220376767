export const VocabConstant = {
  DEFAULTERR: 'sorry unable to process! please try again later',

  GET_VOCAB_DATA_SUCCESS: 'GET_VOCAB_DATA_SUCCESS',
  GET_VOCAB_DATA_FAIL: 'GET_VOCAB_DATA_FAIL',

  SAVE_VOCAB_DATA_SUCCESS: 'SAVE_VOCAB_DATA_SUCCESS',
  SAVE_VOCAB_DATA_FAIL: 'SAVE_VOCAB_DATA_FAIL',

  GET_VOCAB_SOURCE_DATA_SUCCESS: 'GET_VOCAB_SOURCE_DATA_SUCCESS',
  GET_VOCAB_SOURCE_DATA_FAIL: 'GET_VOCAB_SOURCE_DATA_FAIL',

  SAVE_VOCAB_SOURCE_DATA_SUCCESS: 'SAVE_VOCAB_SOURCE_DATA_SUCCESS',
  SAVE_VOCAB_SOURCE_DATA_FAIL: 'SAVE_VOCAB_SOURCE_DATA_FAIL',

  GET_VOCAB_SOURCE_AUDIO_SUCCESS: 'GET_VOCAB_SOURCE_AUDIO_SUCCESS',
  GET_VOCAB_SOURCE_AUDIO_FAIL: 'GET_VOCAB_SOURCE_AUDIO_FAIL',

  SAVE_VOCAB_SOURCE_AUDIO_SUCCESS: 'SAVE_VOCAB_SOURCE_AUDIO_SUCCESS',
  SAVE_VOCAB_SOURCE_AUDIO_FAIL: 'SAVE_VOCAB_SOURCE_AUDIO_FAIL',
};
