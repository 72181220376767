import React, { useEffect, useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  alertNotification,
  getHomeworkByCourseAndStudent,
  getKanbanBoardByCourseAndStudent,
} from '../../redux/action';
import HomeWorkContainer from './HomeWorkContainer';
import { homeworkConstant } from '../../redux/constant';

export default function HomeworkPage() {
  const { courseId, studentId } = useParams();
  const homeworkBoard = useSelector((state) => state.homework);
  const [homeworkData, setHomeworkData] = useState({
    lessons: [],
    id: '',
    student: {},
    course: {},
  });
  const dispatch = useDispatch();
  let userDetail = JSON.parse(localStorage.getItem('userDetail'));
  useEffect(() => {
    const { payload, type } = homeworkBoard;
    if (payload) {
      const { lessons, id, student, course } = payload.results;

      if (type === homeworkConstant.GET_HOMEWORK_DATA_SUCCESS) {
        setHomeworkData({ lessons, id, student, course });
      }
      if (type === homeworkConstant.SAVE_HOMEWORK_DATA_SUCCESS) {
        dispatch(alertNotification(payload.message, 'success'));
        setHomeworkData({ lessons, id, student, course });
      }
      if (type === homeworkConstant.SAVE_HOMEWORK_TASK_SUCCESS) {
        dispatch(alertNotification(payload.message, 'success'));
        setHomeworkData({ lessons, id, student, course });
      }
    }
  }, [homeworkBoard]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (userDetail.role == 'Teacher') {
      dispatch(getHomeworkByCourseAndStudent(courseId, studentId));
    }
    if (userDetail.role == 'Student') {
      console.log(userDetail);
      dispatch(getHomeworkByCourseAndStudent(courseId, userDetail._id));
    }
  };

  return (
    <>
      <div
        style={{
          overflowY: 'scroll',
          minHeight: '80vh',
          padding: 20,
          overflowX: 'scroll',
          border: '1px solid',
          minWidth: 800,
        }}
      >
        <Row>
          <Col xs={3} />
          <Col xs={3}>
            <h6>
              <span style={{ paddingLeft: 15 }}>To Do Homework Tasks</span>
            </h6>
          </Col>
          <Col xs={3}>
            <h6>
              <span style={{ paddingLeft: 15 }}>Inprogress Homework tasks</span>
            </h6>
          </Col>
          <Col xs={3}>
            <h6>
              <span style={{ paddingLeft: 15 }}>Completed Homework tasks</span>
            </h6>
          </Col>

          <Tab.Container defaultActiveKey={'default-tab'}>
            <Row>
              <Col className="col-3">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item
                    style={{
                      backgroundColor: '#232527',
                      borderRadius: '0.25rem',
                      margin: '3px 0px',
                    }}
                  >
                    <Nav.Link
                      eventKey={`default-tab`}
                      style={{ color: '#ffff' }}
                    >
                      <span>Details</span>
                    </Nav.Link>
                  </Nav.Item>
                  {homeworkData.lessons?.map((lesson) => (
                    <Nav.Item
                      key={`nav-${lesson.id}`}
                      style={{
                        backgroundColor:
                          lesson.status == 'Completed'
                            ? '#398029'
                            : lesson.status == 'Progress'
                            ? '#f15e1b'
                            : lesson.color,
                        borderRadius: '0.25rem',
                        margin: '3px 0px',
                      }}
                    >
                      <Nav.Link
                        eventKey={`tab-${lesson.id}`}
                        style={{ color: '#ffff' }}
                      >
                        <span>
                          {lesson.name} <br />
                          <span style={{ color: 'gold' }}>{lesson.status}</span>
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col className="col-9">
                <Tab.Content>
                  <Tab.Pane eventKey={`default-tab`}>
                    <span>
                      Student Name : {homeworkData.student?.firstName}{' '}
                      {homeworkData.student?.lastName}
                    </span>
                    <br />
                    <span>Course Name : {homeworkData.course?.name}</span>
                  </Tab.Pane>
                  {homeworkData.lessons?.map((lesson) => (
                    <Tab.Pane eventKey={`tab-${lesson.id}`}>
                      <HomeWorkContainer
                        lesson={lesson}
                        student={homeworkData.student}
                        courseName={homeworkData.course?.name}
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Row>
      </div>
    </>
  );
}
