import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { getTimeslots, saveCourse } from '../../redux/action';
import { TimeslotConstant } from '../../redux/constant';

export default function CourseForm({ showPopup, selectedCourse, onClose }) {
  const dispatch = useDispatch();
  const [timeslots, setTimeslots] = useState([]);
  const [selectedTimeslots, setSelectedTimeslots] = useState([]);
  const timeslot = useSelector((state) => state.timeslot);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  let userDetail = JSON.parse(localStorage.getItem('userDetail'));

  useEffect(() => {
    if (showPopup) {
      dispatch(getTimeslots(userDetail?._id));
      if (selectedCourse) {
        reset({ ...selectedCourse, name: selectedCourse.courseName });
        setSelectedTimeslots(
          selectedCourse?.timeSlots.map((slot) => {
            return { value: slot._id, label: `${slot.from} - ${slot.to}` };
          })
        );
      } else {
        reset({ name: '', description: '', _id: '', color: '' });
        setSelectedTimeslots([]);
      }
    }
  }, [showPopup]);

  useEffect(() => {
    const { type, payload, error } = timeslot;
    if (type === TimeslotConstant.GET_TIMESLOT_SUCCESS) {
      setTimeslots(
        payload?.results?.map((slot) => {
          return { value: slot.id, label: slot.slot };
        })
      );
    }
  }, [timeslot]);

  const onSaveCourse = (data) => {
    const { name, description, color, _id, maximumStudents, sharedFolderId } =
      data;
    if (selectedCourse) {
      let newTimeSlots = selectedTimeslots
        .map((time) => time.value)
        .filter(
          (t) => !selectedCourse.timeSlots.map((time) => time._id).includes(t)
        );
      // deleted
      let deletedTimeSlots = [];
      if (
        newTimeSlots.length === 0 &&
        selectedCourse.timeSlots.length !== selectedTimeslots.length
      ) {
        deletedTimeSlots = selectedCourse.timeSlots
          .map((time) => time._id)
          .filter(
            (t) => !selectedTimeslots.map((time) => time.value).includes(t)
          );
      }
      dispatch(
        saveCourse(_id, {
          maximumStudents,
          courseName: name,
          description,
          color,
          courseId: _id,
          timeSlots: selectedTimeslots?.map((slot) => {
            return slot?.value;
          }),
          newTimeSlots,
          deletedTimeSlots,
          alreadySlotCount: selectedCourse.timeSlots.length,
          sharedFolderId,
        })
      );
    } else {
      dispatch(
        saveCourse(null, {
          maximumStudents,
          courseName: name,
          description,
          color,
          timeSlots: selectedTimeslots?.map((slot) => {
            return slot?.value;
          }),
        })
      );
    }
  };

  return (
    <Modal
      className="modal-modal-dialog-centered"
      show={showPopup}
      centered
      onHide={onClose}
    >
      <ModalHeader closeButton>
        {!selectedCourse ? 'Create Course' : 'Edit Course'}
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="row form-row">
            <div className="col-12 col-md-12">
              <div className="form-group">
                <label>Colour</label>
                <input
                  type="color"
                  name="color"
                  className="form-control"
                  {...register('color', { required: true })}
                  placeholder="Select Colour"
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>Course Name</label>
                <input
                  type="text"
                  className="form-control"
                  {...register('name', { required: true })}
                  placeholder="Enter course name"
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>Description</label>
                <textarea
                  rows="3"
                  className="form-control"
                  name="description"
                  {...register('description', { required: true })}
                  placeholder="Enter Description"
                ></textarea>
              </div>
            </div>

            <div className="col-12 ">
              <div className="form-group">
                <label>Maximum student for a class</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('maximumStudents', { required: true })}
                  placeholder="Enter Maximum student for a class"
                />
              </div>
            </div>

            <div className="col-12 col-md-12">
              <div className="form-group">
                <label>Timeslots</label>
                <Select
                  isMulti
                  options={timeslots}
                  value={selectedTimeslots}
                  onChange={(slots) => {
                    setSelectedTimeslots(slots);
                  }}
                />
              </div>
            </div>
            {selectedCourse && (
              <div className="col-12 ">
                <div className="form-group">
                  <label>Shared Folder Id</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register('sharedFolderId', { required: true })}
                    placeholder="Enter Google drive Folder Id"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="submit-section">
            <button
              type="submit"
              onClick={handleSubmit(onSaveCourse)}
              className="btn btn-primary submit-btn"
            >
              Save Changes
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}
