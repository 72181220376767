import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { BLOG_01 } from '../../constant/imagePaths';
import { alertNotification, getMentors } from '../../redux/action';
import { CourseConstant } from '../../redux/constant';
const MentorList = () => {
  const [mentors, setMentors] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const course = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getMentors());
  }, []);

  useEffect(() => {
    const { type, payload, error } = course;
    if (type === CourseConstant.GET_MENTOR_LIST_SUCCESS) {
      setMentors(payload);
    }
    if (type === CourseConstant.GET_MENTOR_LIST_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
  }, [course]);

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
          result[3],
          16
        )},0.3)`
      : null;
  }
  return (
    <>
      <div className="row">
        {mentors?.map((courseRec, i) => {
          const {
            courseId,
            courseName,
            courseDescription,
            courseColor,
            enrolStatus,
            teacherId,
            teacherName,
          } = courseRec;
          return (
            <div className="col-12 col-md-6 col-xl-4" key={courseId}>
              <div
                className="course-box blog grid-blog"
                style={{ backgroundColor: hexToRgb(courseColor) }}
              >
                <div className="blog-image mb-0">
                  <Link to={`/student/entroll/${courseId}`}>
                    <img className="img-fluid" src={BLOG_01} alt="Post Image" />
                  </Link>
                </div>
                <div className="course-content">
                  {/* <span className="date">April 09 2020</span> */}
                  <span className="course-title">{courseName}</span>
                  <br />
                  <span className="course-title">{teacherName}</span>
                  <p>{courseDescription}</p>
                  <div className="row">
                    <div className="col text-right">
                      {enrolStatus == 'Enroll' ? (
                        <Link
                          to={`/student/enroll/${courseId}`}
                          state={courseRec}
                          className="text-danger"
                        >
                          Enroll
                        </Link>
                      ) : (
                        <Link className="text-danger">{enrolStatus}</Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MentorList;
