import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from '../components/layout/header';
import StudentSideBar from '../components/layout/studentSidebar';
import Dashboard from '../pages/dashboard';
import CourseList from './course';
import CourseListForForum from './forum';
import QuestionList from './forum/questions';
import SingleQuestion from './forum/singleQuestion';
import EntrollCourse from './mentor/enrollcourse';
import MentorList from './mentor/index';
import Payment from './payment';
import PaymentCard from './paymentcard';
import Profile from './profile';
import TodoPage from './todo/Index';
import HomeworkPage from './homework-board';
import CourseVocabs from './course/vocabs/CourseVocabs';
import SingleVocab from './course/vocabs/SingleVocab';

function StudentRoute() {
  if (localStorage.getItem('userDetail')) {
    let userDetail = JSON.parse(localStorage.getItem('userDetail'));

    if (userDetail?.role !== 'Student') {
      window.location.href = `${process.env.REACT_APP_LOGOUT_URL}`;
    }
  } else {
    window.location.href = `${process.env.REACT_APP_LOGOUT_URL}`;
  }

  return (
    <>
      <Header />
      <div>
        {/* <div className="breadcrumb-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/app/index">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Profile Settings</li>
                  </ol>
                </nav>
                <h2 className="breadcrumb-title">Profile Settings</h2>
              </div>
            </div>
          </div>
        </div> */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3">
                <StudentSideBar />
              </div>
              <div className="col-md-7 col-lg-8 col-xl-9">
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/payment" element={<Payment />} />
                  <Route path="/payment-card" element={<PaymentCard />} />
                  <Route path="/mentors" element={<MentorList />} />
                  <Route path="/forum" element={<CourseListForForum />} />
                  <Route
                    path="/questions/:courseId"
                    element={<QuestionList />}
                  />
                  <Route
                    path="/question/:questionId"
                    element={<SingleQuestion />}
                  />
                  <Route path="/courses" element={<CourseList />} />
                  <Route path="/enroll/:courseId" element={<EntrollCourse />} />
                  <Route
                    path="/courses/:courseId/kanban-board"
                    element={<TodoPage />}
                  />
                  <Route
                    path="/courses/:courseId/home-work"
                    element={<HomeworkPage />}
                  />
                  <Route
                    path="/courses/:courseId/vocab-sources"
                    element={<CourseVocabs />}
                  />
                  <Route
                    path="/course/:courseId/vocab-sources/:vocabId"
                    element={<SingleVocab />}
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentRoute;
