import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AvailableSlotCell from '../../components/course/AvailableSlotCell';
import { weeks } from '../../data';
import {
  alertNotification,
  getAvailableSlots,
  getEntrollCourseSlots,
  saveAvailableSlots,
} from '../../redux/action';
import { CourseConstant } from '../../redux/constant';

const CourseAvailableSlots = ({ courseId }) => {
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [entrollSlots, setEntrollSlots] = useState([]);
  const [availablrEntrollSlots, setAvailablrEntrollSlots] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const course = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getAvailableSlots(courseId));
  }, []);

  useEffect(() => {
    const { type, payload, error } = course;
    if (type === CourseConstant.GET_AVAILABLE_SLOTS_SUCCESS) {
      setAvailableTimeSlots(payload?.results);
      dispatch(getEntrollCourseSlots(courseId));
    }
    if (type === CourseConstant.GET_AVAILABLE_SLOTS_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
    if (type === CourseConstant.GET_ENTROLL_COURSE_SLOTS_SUCCESS) {
      setEntrollSlots(payload?.results);
    }

    if (type === CourseConstant.SAVE_AVAILABLE_SLOTS_SUCCESS) {
      dispatch(alertNotification(error, 'success'));
      dispatch(getAvailableSlots(courseId));
    }
    if (type === CourseConstant.SAVE_AVAILABLE_SLOTS_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
  }, [course]);

  useEffect(() => {
    if (entrollSlots?.length > 0) {
      let availableSlots = entrollSlots
        .filter((cell) => cell.isAvailable)
        .map((cell) => {
          return cell.index;
        });
      console.log('availableSlots', availableSlots);
      setAvailablrEntrollSlots(availableSlots);
    }
  }, [entrollSlots]);

  const saveEntrollSlots = () => {
    dispatch(saveAvailableSlots(courseId, availablrEntrollSlots));
  };

  const changeSelectenrollCells = async (status, cellId) => {
    if (status) {
      let availablrEntrollSlotsArr = availablrEntrollSlots;
      await setAvailablrEntrollSlots([...availablrEntrollSlotsArr, cellId]);
      console.log({ cellId });
    } else {
      if (availablrEntrollSlots.includes(cellId)) {
        let checkedArr = availablrEntrollSlots;
        const index = checkedArr.indexOf(cellId);
        if (index > -1) {
          checkedArr.splice(index, 1);
          await setAvailablrEntrollSlots(checkedArr);
        }
      }
    }
  };

  return (
    <>
      <div className="card card-table">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-hover table-center mb-0">
              <thead>
                <tr>
                  <th>Time</th>
                  {weeks.map((week) => (
                    <th key={`th-${week.id}`}>{week.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {availableTimeSlots.map((row) => (
                  <tr key={`slot-${row.id}`}>
                    <td>{row.time}</td>
                    {entrollSlots.map(
                      (cell) =>
                        row.id === cell.rowId && (
                          <td>
                            <AvailableSlotCell
                              cell={cell}
                              changeSelectenrollCells={changeSelectenrollCells}
                            />
                          </td>
                        )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="my-2 col-12 d-flex justify-content-end align-items-lg-center">
        <Link
          onClick={() => {
            saveEntrollSlots();
          }}
          className="btn view-btn"
        >
          Save Changes
        </Link>
      </div>
    </>
  );
};

export default CourseAvailableSlots;
