export const KanbanBoardConstant = {
    DEFAULTERR: "sorry unable to process! please try again later",

    GET_KANBAN_BOARD_DATA_SUCCESS : "GET_KANBAN_BOARD_DATA_SUCCESS",
    GET_KANBAN_BOARD_DATA_FAIL: "GET_KANBAN_BOARD_DATA_FAIL",
    
    SAVE_KANBAN_BOARD_DATA_SUCCESS : "SAVE_KANBAN_BOARD_DATA_SUCCESS",
    SAVE_KANBAN_BOARD_DATA_FAIL: "SAVE_KANBAN_BOARD_DATA_FAIL",
    SAVE_KANBAN_BOARD_DATA_REQUEST:"SAVE_KANBAN_BOARD_DATA_REQUEST"
}
