import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import App from '../src/pages/App';
import reducer from './redux/reducer';

import './assets/css/bootstrap.min.css';
import './assets/css/modal.css';
import './assets/css/style.css';
import './assets/plugins/fontawesome/css/all.min.css';
import './assets/plugins/fontawesome/css/fontawesome.min.css';

const authMiddleware = (store) => (next) => async (action) => {
  next(action);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
let middleware = [authMiddleware, thunk];

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  //  </React.StrictMode>,
);
