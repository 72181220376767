import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createHomeworkTask, saveTask } from '../../redux/action';
import { useParams } from 'react-router-dom';

const SaveHomeworkTaskModal = ({
  showPopup,
  selectedTask,
  onClose,
  lesson,
  studentEmail,
  courseName,
  homeworkBoardId,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const [content, setContent] = useState({});
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { studentId } = useParams();
  useEffect(() => {
    if (showPopup) {
      if (selectedTask) {
        reset(selectedTask);
        setContent(selectedTask?.content);
      } else {
        reset({
          title: '',
          type: '',
          _id: '',
          lessonId: lesson.id,
          studentId: studentId,
        });
        setContent({});
      }
    }
  }, [showPopup]);
  useEffect(() => {
    console.log(homeworkBoardId);
  }, [homeworkBoardId]);

  const onSaveTask = (data) => {
    const { title, _id, dueDate } = data;
    let mailBody = {
      mailContent: `<h4>Homework Task is created.</h4>
      <ul><li>Course : ${courseName}</li><li>Lesson : ${lesson.name}</li><li>Task : ${title}</li></ul> 
      <p>please complete the task before <b> ${dueDate}</b></p>`,
      studentEmail,
    };
    setLoading(true);
    dispatch(
      createHomeworkTask(_id, {
        boardId: homeworkBoardId,
        title,
        lessonId: lesson.id,
        studentId,
        content,
        dueDate,
        mailBody,
      })
    );
  };

  return (
    <Modal
      size="xl"
      fullscreen={false}
      className="modal-modal-dialog-centered"
      show={showPopup}
      centered
      onHide={() => {
        setContent({});
        onClose();
      }}
    >
      <ModalHeader closeButton>
        {!selectedTask ? 'Create Homework task' : 'Edit Homework task'}
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="hours-info">
            <div className="row form-row hours-cont">
              <div className="col-12">
                <div className="row form-row">
                  <div className="col-12 col-md-12">
                    <div className="form-group">
                      <label>Task Title</label>
                      <input
                        type="text"
                        name="taskName"
                        className="form-control"
                        {...register('title', { required: true })}
                        placeholder="Enter Name"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Due Date</label>
                      <input
                        type="date"
                        className="form-control"
                        {...register('dueDate', { required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Type</label>
                      <select
                        className="form-control select"
                        disabled
                        value={'Homework'}
                      >
                        <option>Homework</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <CKEditor
                        editor={ClassicEditor}
                        data={content ? content : {}}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setContent(data);
                          console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="submit-section text-center">
            <button
              type="submit"
              onClick={handleSubmit(onSaveTask)}
              className="btn btn-primary submit-btn"
            >
              Save Changes
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default SaveHomeworkTaskModal;
