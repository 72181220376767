import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  acceptPayment,
  alertNotification,
  getPayments,
  rejectPayment,
} from '../../redux/action';
import { PaymentConstant } from '../../redux/constant';
import NewPayment from './newPayment';
const Payment = () => {
  const [payments, setPayments] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const payment = useSelector((state) => state.payment);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [savePaymentPopup, setSavePaymentPopup] = useState(false);

  let userDetail = JSON.parse(localStorage.getItem('userDetail'));

  useEffect(() => {
    dispatch(getPayments());
  }, []);

  useEffect(() => {
    const { type, payload, error } = payment;
    if (type === PaymentConstant.GET_PAYMENT_DATA_SUCCESS) {
      setPayments(payload?.results);
    }
    if (type === PaymentConstant.GET_PAYMENT_DATA_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
    if (type === PaymentConstant.ACCEPT_PAYMENT_SUCCESS) {
      dispatch(alertNotification(error, 'success'));
      dispatch(getPayments());
    }
    if (type === PaymentConstant.ACCEPT_PAYMENT_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
    if (type === PaymentConstant.REJECT_PAYMENT_SUCCESS) {
      dispatch(alertNotification(error, 'success'));
      dispatch(getPayments());
    }
    if (type === PaymentConstant.REJECT_PAYMENT_FAIL) {
      dispatch(alertNotification(error, 'fail'));
    }
  }, [payment]);

  const changePaymentStatus = (status, id) => {
    if (
      window.confirm(
        status === 'accept'
          ? 'Are you sure about accept this payment?'
          : 'Are you sure about reject this payment?'
      )
    )
      if (status === 'accept') {
        dispatch(acceptPayment(id));
      } else {
        dispatch(rejectPayment(id));
      }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 col-12">
          <div
            className="mentor-booking"
            style={{ width: '200px', marginBottom: '10px', float: 'right' }}
          >
            <a
              className="btn apt-btn"
              onClick={() => {
                setSavePaymentPopup(true);
              }}
            >
              New Payment
            </a>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-12">
          <div className="card card-table">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Date</th>
                      <th>Account Name</th>
                      <th>Reference No</th>
                      <th>Amount($)</th>
                      <th>Month</th>
                      <th>Status</th>
                      <th>Actions</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {payments?.map((payRec, i) => {
                      const {
                        _id,
                        accountName,
                        amount,
                        date,
                        month,
                        referenceNo,
                        status,
                        student,
                      } = payRec;
                      const { firstName, lastName } = student;
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            {firstName} {lastName}
                          </td>
                          <td>{moment(date).format('YYYY-MM-DD')}</td>
                          <td>{accountName}</td>
                          <td>{referenceNo}</td>
                          <td>{amount}</td>
                          <td>{month}</td>
                          <td>
                            <span
                              className={
                                status === 'NotPaid'
                                  ? 'pending'
                                  : status === 'Paid'
                                  ? 'accept'
                                  : 'reject'
                              }
                            >
                              {status}
                            </span>
                          </td>
                          <td>
                            <a
                              onClick={() => {
                                setSelectedPayment(payRec);
                                setSavePaymentPopup(true);
                              }}
                              className="btn btn-sm bg-primary-light m-2"
                            >
                              <i className="fas fa-edit" />
                            </a>
                            {userDetail?.role === 'Teacher' && (
                              <>
                                <a
                                  onClick={() => {
                                    changePaymentStatus('accept', _id);
                                  }}
                                  className="btn btn-sm bg-success-light m-2"
                                >
                                  <i className="fas fa-check" />
                                </a>
                                <a
                                  onClick={() => {
                                    changePaymentStatus('reject', _id);
                                  }}
                                  className="btn btn-sm bg-danger-light"
                                >
                                  <i className="fas fa-ban" />
                                </a>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <NewPayment
        selectedPayment={selectedPayment}
        showPopup={savePaymentPopup}
        onClose={(reload) => {
          setSelectedPayment(null);
          setSavePaymentPopup(false);
          if (reload) {
            dispatch(getPayments());
          }
        }}
      />
    </>
  );
};

export default Payment;
