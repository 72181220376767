import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { alertNotification, resetPassword } from '../../redux/action';
import { AuthConstant } from '../../redux/constant';

const ResetPassword = () => {
  const { token } = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [submitLoader, setSubmitLoader] = useState(false);
  const [submit, setSubmit] = useState(false);

  const onSubmit = (data) => {
    setSubmit(true);
    const { password, confirmPassword } = data;
    if (password && confirmPassword && password === confirmPassword) {
      setSubmitLoader(true);
      dispatch(
        resetPassword({
          resetPasswordLink: token ? token : '',
          newPassword: password ? password : ''
        })
      );
    }
  };

  useEffect(() => {
    const { type, payload, error } = auth;
    if (type === AuthConstant.RESET_PASSWORD_SUCCESS) {
      setSubmitLoader(false);
      setSubmit(false);
      if (payload?.message) {
        dispatch(alertNotification(error, 'success'));
        navigate('/login')
      }
      if (payload?.error) {
        dispatch(alertNotification(error, 'fail'));

      }


    }

    if (type === AuthConstant.RESET_PASSWORD_FAIL) {
      dispatch(alertNotification(error, 'fail'));
      setSubmitLoader(false);
      setSubmit(false);
    }
  }, [auth]);
  return (
    <div className="bg-pattern-style">
      <div className="content">
        <div className="account-content">
          <div className="account-box">
            <div className="login-right">
              <div className="login-header">
                <h3>Reset Password</h3>
              </div>
              <form >
                <div className="form-group">
                  <label className="form-control-label">Password</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    required
                    {...register('password', {
                      required: true,
                    })}
                  />
                  {errors.password && (
                    <div
                      className="invalid-feedback"
                      style={{ display: 'block' }}
                    >
                      Please provide valid password
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-control-label">Confirm Password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    className="form-control"
                    required
                    {...register('confirmPassword', {
                      required: true,
                    })}
                  />
                  {errors.confirmPassword && (
                    <div
                      className="invalid-feedback"
                      style={{ display: 'block' }}
                    >
                      Please provide valid password
                    </div>
                  )}
                  {
                    submit && watch('password') != watch('confirmPassword') &&
                    <div
                      className="invalid-feedback"
                      style={{ display: 'block' }}
                    >
                      Password and confirm password doesn't match
                    </div>
                  }
                </div>
                <Link className="btn btn-primary login-btn" disabled={submitLoader} onClick={handleSubmit(onSubmit)}>           {submitLoader ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  'Reset Password'
                )}</Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
