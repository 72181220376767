import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createVocabSourceAudio } from '../../../redux/action/vocabAction';

export default function AddVocabSourceAudio({
  showPopup,
  selectedVocabSourceAudio,
  onClose,
  vocabSourceId,
  setLoading,
  isLoading,
}) {
  const [uploadAudio, setUploadAudio] = useState(null);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleAudioUpload = (e) => {
    console.log(e.target.files[0]);
    setUploadAudio(e.target.files[0]);
  };
  useEffect(() => {
    if (showPopup) {
      if (selectedVocabSourceAudio) {
      } else {
        reset({
          text: '',
          language: 'English',
        });
        setUploadAudio(null);
      }
    }
  }, [showPopup]);

  const onSaveVocabSourceAudio = (data) => {
    setLoading(true);
    let { text, language } = data;
    let formData = new FormData();
    formData.append('audioFile', uploadAudio);
    formData.append('text', text);
    formData.append('language', language);
    formData.append('vocabSourceId', vocabSourceId);
    dispatch(createVocabSourceAudio(formData, vocabSourceId));
  };
  return (
    <Modal
      size="lg"
      className="modal-modal-dialog-centered"
      show={showPopup}
      centered
      onHide={onClose}
    >
      <ModalHeader closeButton>
        {!selectedVocabSourceAudio
          ? 'Create Vocab Source Audio'
          : 'Edit Vocab Source Audio'}
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="hours-info">
            <div className="row form-row hours-cont">
              <div className="col-12">
                <div className="row form-row px-4">
                  <div className="col-12 col-md-12">
                    <div className="form-group">
                      <div
                        className="row p-3 mb-3 mt-3 d-flex align-items-center"
                        style={{ backgroundColor: '#f5f2efc4' }}
                      >
                        <div className="form-group col-xs-12 col-lg-6">
                          <label htmlFor="fa_question">Language</label>
                          <select
                            name="language"
                            className="form-control"
                            {...register('language', { required: true })}
                          >
                            <option value="English">English</option>
                            <option value="Tamil">Tamil</option>
                          </select>
                        </div>

                        <div className="form-group col-xs-12 col-lg-6">
                          <label htmlFor="fa_answer">Text</label>
                          <input
                            type="text"
                            name="text"
                            className="form-control"
                            placeholder="Enter vocabulary"
                            {...register('text', { required: true })}
                          />
                        </div>

                        <div className="form-group col-xs-12">
                          <label htmlFor="fa_answer">Audio</label>
                          <input
                            type="file"
                            name="audio"
                            accept="audio/mpeg"
                            className="form-control"
                            onChange={handleAudioUpload}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="submit-section text-right">
            <button
              type="submit"
              onClick={handleSubmit(onSaveVocabSourceAudio)}
              className="btn btn-primary submit-btn"
              disabled={isLoading}
            >
              {isLoading ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                'Save Changes'
              )}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}
