import {
    authHeader,
    endPointConnect
} from "../../utils/authHelper";
import { ForumConstant } from "../constant";
  
export const getQuestions = (courseId) => (dispatch) => {
    try {
        const requestData = {
            method: "GET",
            headers: authHeader()
        };
        endPointConnect(
            'forum/' + courseId,
            requestData,
            ForumConstant.GET_QUESTION_LIST_SUCCESS,
            ForumConstant.GET_QUESTION_LIST_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: ForumConstant.GET_QUESTION_LIST_FAIL,
            payload: "",
            error: ForumConstant.DEFAULTERR,
        });
    }
};

export const saveQuestion = (type, data) => (dispatch) => {
    const { courseId, questionId } = data;
    try {
        const requestData = {
            method: type === 'edit' ? "PUT" : "POST",
            headers: authHeader(),
            body: JSON.stringify(data)
        };
        endPointConnect(
            `forum/question/${type === 'edit' ? questionId : courseId}`,
            requestData,
            ForumConstant.SAVE_QUESTION_SUCCESS,
            ForumConstant.SAVE_QUESTION_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: ForumConstant.SAVE_QUESTION_FAIL,
            payload: "",
            error: ForumConstant.DEFAULTERR,
        });
    }
};

export const getQuestion = (questionId) => (dispatch) => {
    try {
      const requestData = {
        method: "GET",
        headers: authHeader()
      };
      endPointConnect(
        'forum/question/' + questionId ,
        requestData,
        ForumConstant.GET_QUESTION_SUCCESS,
        ForumConstant.GET_QUESTION_FAIL,
        dispatch
      );
    } catch (err) {
      dispatch({
        type: ForumConstant.GET_QUESTION_FAIL,
        payload: "",
        error: ForumConstant.DEFAULTERR,
      });
    }
  };

  export const saveAnswer = (type, data) => (dispatch) => {
    const { questionId, answerId } = data;
    try {
        const requestData = {
            method: type === 'edit' ? "PUT" : "POST",
            headers: authHeader(),
            body: JSON.stringify(data)
        };
        endPointConnect(
            `forum/answer/${type === 'edit' ? answerId : questionId}`,
            requestData,
            ForumConstant.SAVE_ANSWER_SUCCESS,
            ForumConstant.SAVE_ANSWER_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: ForumConstant.SAVE_ANSWER_FAIL,
            payload: "",
            error: ForumConstant.DEFAULTERR,
        });
    }
};

export const deleteQuestion = (questionId) => (dispatch) => {
    try {
      const requestData = {
        method: "DELETE",
        headers: authHeader()
      };
      endPointConnect(
        'forum/question/' + questionId ,
        requestData,
        ForumConstant.DELETE_QUESTION_SUCCESS,
        ForumConstant.DELETE_QUESTION_FAIL,
        dispatch
      );
    } catch (err) {
      dispatch({
        type: ForumConstant.DELETE_QUESTION_FAIL,
        payload: "",
        error: ForumConstant.DEFAULTERR,
      });
    }
  };

  export const deleteAnswer = (answerId) => (dispatch) => {
    try {
      const requestData = {
        method: "DELETE",
        headers: authHeader()
      };
      endPointConnect(
        'forum/answer/' + answerId ,
        requestData,
        ForumConstant.DELETE_ANSWER_SUCCESS,
        ForumConstant.DELETE_ANSWER_FAIL,
        dispatch
      );
    } catch (err) {
      dispatch({
        type: ForumConstant.DELETE_ANSWER_FAIL,
        payload: "",
        error: ForumConstant.DEFAULTERR,
      });
    }
  };
