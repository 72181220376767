import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { alertNotification } from "../../redux/action";
import { getPayments } from "../../redux/action/paymentActions";
import { PaymentConstant } from "../../redux/constant";
const PaymentCard = ({ id }) => {
    const [payments, setPayments] = useState([])
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const payment = useSelector((state) => state.payment);
    let userDetail = JSON.parse(localStorage.getItem('userDetail'))
    const { role, _id } = userDetail;

    useEffect(() => {
        if (role === "Student") {
            dispatch(getPayments(_id))

        } else {
            dispatch(getPayments(id))

        }
    }, []);

    useEffect(() => {
        const { type, payload, error } = payment;
        if (type === PaymentConstant.GET_PAYMENT_DATA_SUCCESS) {
            setPayments(payload?.results)
        }
        if (type === PaymentConstant.GET_PAYMENT_DATA_FAIL) {
            dispatch(alertNotification(error, 'fail'))
        }
    }, [payment]);
    return (
        <>
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="card card-table">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-hover table-center mb-0">
                                    <thead>
                                        <tr>
                                            <th>Month</th>
                                            <th>Status</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            payments?.map((payRec, i) => {
                                                const {
                                                    month,
                                                    status,
                                                } = payRec;
                                                return (
                                                    <tr>
                                                        <td>{month}</td>
                                                        <td><span className={status === "NotPaid" ? "pending" : status === "Paid" ? "accept" : "reject"}>{status}</span></td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PaymentCard;