import { authHeader, endPointConnect } from '../../utils/authHelper';
import { homeworkConstant } from '../constant/homeworkConstant';

export const getHomeworkByCourseAndStudent =
  (courseId, studentId) => (dispatch) => {
    try {
      const requestData = {
        method: 'GET',
        headers: authHeader(),
      };
      endPointConnect(
        `home-work/course/${courseId}/student/${studentId}`,
        requestData,
        homeworkConstant.GET_HOMEWORK_DATA_SUCCESS,
        homeworkConstant.GET_HOMEWORK_DATA_FAIL,
        dispatch
      );
    } catch (err) {
      dispatch({
        type: homeworkConstant.GET_HOMEWORK_DATA_FAIL,
        payload: '',
        error: homeworkConstant.DEFAULTERR,
      });
    }
  };

export const updateHomework = (id, data) => (dispatch) => {
  try {
    const requestData = {
      method: 'PUT',
      headers: authHeader(),
      body: JSON.stringify(data),
    };
    endPointConnect(
      `home-work/${id}`,
      requestData,
      homeworkConstant.SAVE_HOMEWORK_DATA_SUCCESS,
      homeworkConstant.SAVE_HOMEWORK_DATA_FAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: homeworkConstant.SAVE_HOMEWORK_DATA_FAIL,
      payload: '',
      error: homeworkConstant.DEFAULTERR,
    });
  }
};

export const createHomeworkTask = (id, data) => (dispatch) => {
  try {
    const requestData = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(data),
    };
    endPointConnect(
      `home-work/tasks`,
      requestData,
      homeworkConstant.SAVE_HOMEWORK_TASK_SUCCESS,
      homeworkConstant.SAVE_HOMEWORK_TASK_FAIL,
      dispatch
    );
  } catch (err) {
    dispatch({
      type: homeworkConstant.SAVE_HOMEWORK_TASK_FAIL,
      payload: '',
      error: homeworkConstant.DEFAULTERR,
    });
  }
};
