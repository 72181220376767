import { Link } from "react-router-dom";
const StudentSideBar = () => {
    const pathname = 'index'
    let userDetail = JSON.parse(localStorage.getItem('userDetail'))

    const logout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userDetail");
        window.location.href = `${process.env.REACT_APP_LOGOUT_URL}`;
    }
    return (
        <div className="profile-sidebar">
            <div className="user-widget">
                <div className="pro-avatar">{userDetail?.firstName?.substring(0, 1)}{userDetail?.lastName?.substring(0, 1)}</div>
                {/* <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star" />
                </div> */}
                <div className="user-info-cont">
                    <h4 className="usr-name">{userDetail?.firstName} {userDetail?.lastName} </h4>
                    {/* <p className="mentor-type">English Literature (M.A)</p> */}
                </div>
            </div>
            {/* <div className="progress-bar-custom">
                <h6>Complete your profiles &gt;</h6>
                <div className="pro-progress">
                    <div className="tooltip-toggle" tabIndex={0} />
                    <div className="tooltip">80%</div>
                </div>
            </div> */}
            <div className="custom-sidebar-nav">
                <ul>
                    {/* <li><Link to="/student/" className={pathname.includes('dashboard') ? "active" : ""}><i className="fas fa-home" />Dashboard <span><i className="fas fa-chevron-right" /></span></Link></li> */}
                    <li><Link to="/student/mentors" className={pathname.includes('mentors') ? "active" : ""}><i className="fas fa-clock" />Mentors <span><i className="fas fa-chevron-right" /></span></Link></li>
                    <li><Link to="/student/courses" className={pathname.includes('courses') ? "active" : ""}><i className="fas fa-hourglass-start" />Courses <span><i className="fas fa-chevron-right" /></span></Link></li>
                    <li><Link to="/student/profile" className={pathname.includes('profile') ? "active" : ""}><i className="fas fa-user-cog" />Profile <span><i className="fas fa-chevron-right" /></span></Link></li>
                    <li><Link to="/student/payment"><i className="fas fa-credit-card" />Payments <span><i className="fas fa-chevron-right" /></span></Link></li>
                    <li><Link to="/student/payment-card" className={pathname.includes('invoices') ? "active" : ""}><i className="fas fa-file-invoice" />Payment Card <span><i className="fas fa-chevron-right" /></span></Link></li>
                    <li><Link to="/student/forum"><i className="fas fa-question" />Questions & Answers <span><i className="fas fa-chevron-right" /></span></Link></li>
                    <li><Link onClick={() => { logout() }}><i className="fas fa-sign-out-alt" />Logout <span><i className="fas fa-chevron-right" /></span></Link></li>
                </ul>
            </div>
        </div>
    )
}

export default StudentSideBar;