import { ProfileConstant } from "../constant";
const initialState = {
    payload: "",
    type: '',
    error: ""
}

export default function profileReducers(state = initialState, action) {
    switch (action.type) {
        case ProfileConstant.GET_PROFILE_DATA_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case ProfileConstant.GET_PROFILE_DATA_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case ProfileConstant.SAVE_PROFILE_DATA_SUCCESS:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        case ProfileConstant.SAVE_PROFILE_DATA_FAIL:
            return {
                payload: action.payload,
                type: action.type,
                error: action.error
            };
        default:
            return state
    }
}