import {
    authHeader,
    endPointConnect
} from "../../utils/authHelper";
import { CourseConstant } from "../constant";

export const getCourses = () => (dispatch) => {
    try {
        const requestData = {
            method: "GET",
            headers: authHeader()
        };
        endPointConnect(
            'course/my-courses',
            requestData,
            CourseConstant.GET_COURSE_DATA_SUCCESS,
            CourseConstant.GET_COURSE_DATA_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: CourseConstant.GET_COURSE_DATA_FAIL,
            payload: "",
            error: CourseConstant.DEFAULTERR,
        });
    }
};

export const getLessons = (course) => (dispatch) => {
    try {
        const requestData = {
            method: "GET",
            headers: authHeader()
        };
        endPointConnect(
            'lessons/course/'+course,
            requestData,
            CourseConstant.GET_LESSON_LIST_SUCCESS,
            CourseConstant.GET_LESSON_LIST_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: CourseConstant.GET_LESSON_LIST_FAIL,
            payload: "",
            error: CourseConstant.DEFAULTERR,
        });
    }
};

export const getTasks = (lesson) => (dispatch) => {
    try {
        const requestData = {
            method: "GET",
            headers: authHeader()
        };
        endPointConnect(
            'tasks/lesson/'+lesson,
            requestData,
            CourseConstant.GET_TASK_LIST_SUCCESS,
            CourseConstant.GET_TASK_LIST_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: CourseConstant.GET_TASK_LIST_FAIL,
            payload: "",
            error: CourseConstant.DEFAULTERR,
        });
    }
};

export const getCourseStudents = (course) => (dispatch) => {
    try {
        const requestData = {
            method: "GET",
            headers: authHeader()
        };
        endPointConnect(
            'course/getStudents/'+course,
            requestData,
            CourseConstant.GET_COURSE_STUDENT_LIST_SUCCESS,
            CourseConstant.GET_COURSE_STUDENT_LIST_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: CourseConstant.GET_COURSE_STUDENT_LIST_FAIL,
            payload: "",
            error: CourseConstant.DEFAULTERR,
        });
    }
};

export const getAvailableSlots = (course) => (dispatch) => {
    try {
        const requestData = {
            method: "GET",
            headers: authHeader()
        };
        endPointConnect(
            `course/getCourse/${course}/getTime`,
            requestData,
            CourseConstant.GET_AVAILABLE_SLOTS_SUCCESS,
            CourseConstant.GET_AVAILABLE_SLOTS_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: CourseConstant.GET_AVAILABLE_COURSE_SLOTS_FAIL,
            payload: "",
            error: CourseConstant.DEFAULTERR,
        });
    }
};

export const getEntrollCourseSlots = (course) => (dispatch) => {
    try {
        const requestData = {
            method: "GET",
            headers: authHeader()
        };
        endPointConnect(
            `course/getCourse/${course}/getCells`,
            requestData,
            CourseConstant.GET_ENTROLL_COURSE_SLOTS_SUCCESS,
            CourseConstant.GET_ENTROLL_COURSE_SLOTS_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: CourseConstant.GET_ENTROLL_COURSE_SLOTS_FAIL,
            payload: "",
            error: CourseConstant.DEFAULTERR,
        });
    }
};

export const saveAvailableSlots = (course,availableSlots) => (dispatch) => {
    try {
        const requestData = {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify({
                availableSlots:availableSlots
            })
        };
        endPointConnect(
            `course/course-cells/${course}/available-slots`,
            requestData,
            CourseConstant.SAVE_AVAILABLE_SLOTS_SUCCESS,
            CourseConstant.SAVE_AVAILABLE_SLOTS_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: CourseConstant.SAVE_AVAILABLE_SLOTS_FAIL,
            payload: "",
            error: CourseConstant.DEFAULTERR,
        });
    }
};

export const getRequestCourseStudents = (course) => (dispatch) => {
    try {
        const requestData = {
            method: "GET",
            headers: authHeader()
        };
        endPointConnect(
            'course/requestCourseStudents/'+course,
            requestData,
            CourseConstant.GET_REQUEST_COURSE_STUDENT_LIST_SUCCESS,
            CourseConstant.GET_REQUEST_COURSE_STUDENT_LIST_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: CourseConstant.GET_REQUEST_COURSE_STUDENT_LIST_FAIL,
            payload: "",
            error: CourseConstant.DEFAULTERR,
        });
    }
};

export const saveLesson = (lesson,data) => (dispatch) => {
    try {
        const requestData = {
            method: lesson ? "PUT" : "POST",
            headers: authHeader(),
            body: JSON.stringify(data)
        };
        endPointConnect(
            lesson ? 'lessons/'+lesson : 'lessons',
            requestData,
            CourseConstant.SAVE_LESSON_DATA_SUCCESS,
            CourseConstant.SAVE_LESSON_DATA_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: CourseConstant.SAVE_LESSON_DATA_FAIL,
            payload: "",
            error: CourseConstant.DEFAULTERR,
        });
    }
};

export const getStudents = () => (dispatch) => {
    try {
        const requestData = {
            method: "GET",
            headers: authHeader()
        };
        endPointConnect(
            'user/students/all',
            requestData,
            CourseConstant.GET_STUDENTS_LIST_SUCCESS,
            CourseConstant.GET_STUDENTS_LIST_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: CourseConstant.GET_STUDENTS_LIST_FAIL,
            payload: "",
            error: CourseConstant.DEFAULTERR,
        });
    }
};

export const getMentors = () => (dispatch) => {
    try {
        const requestData = {
            method: "GET",
            headers: authHeader()
        };
        endPointConnect(
            'teacher/getAll',
            requestData,
            CourseConstant.GET_MENTOR_LIST_SUCCESS,
            CourseConstant.GET_MENTOR_LIST_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: CourseConstant.GET_MENTOR_LIST_FAIL,
            payload: "",
            error: CourseConstant.DEFAULTERR,
        });
    }
};

export const saveCourse = (course,data) => (dispatch) => {
    try {
        const requestData = {
            method: course ? "PUT" : "POST",
            headers: authHeader(),
            body: JSON.stringify(data)
        };
        endPointConnect(
            course ? 'course/edit/'+course : 'course/create',
            requestData,
            CourseConstant.SAVE_COURSE_DATA_SUCCESS,
            CourseConstant.SAVE_COURSE_DATA_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: CourseConstant.SAVE_COURSE_DATA_FAIL,
            payload: "",
            error: CourseConstant.DEFAULTERR,
        });
    }
};

export const entrollCourse = (course,times) => (dispatch) => {
    try {
        const requestData = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
                times:times
            })
        };
        endPointConnect(
            `course/enroll/${course}`,
            requestData,
            CourseConstant.ENROLL_COURSE_SUCCESS,
            CourseConstant.ENROLL_COURSE_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: CourseConstant.ENROLL_COURSE_FAIL,
            payload: "",
            error: CourseConstant.DEFAULTERR,
        });
    }
};

export const accpetRejectStudent = (studentId,courseId,action,index) => (dispatch) => {
    try {
        const requestData = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
                index: index,
                studentId:studentId
            })
        };
        endPointConnect(
            `course/${action === 'accept' ? 'acceptStudent' :'rejectStudent'}/${courseId}`,
            requestData,
            CourseConstant.ENROLL_STUDENT_SUCCESS,
            CourseConstant.ENROLL_STUDENT_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: CourseConstant.ENROLL_STUDENT_FAIL,
            payload: "",
            error: CourseConstant.DEFAULTERR,
        });
    }
};


export const saveTask = (task,data) => (dispatch) => {
    try {
        const requestData = {
            method: task ? "PUT" : "POST",
            headers: authHeader(),
            body: JSON.stringify(data)
        };
        endPointConnect(
            task ? 'tasks/'+task : 'tasks',
            requestData,
            CourseConstant.SAVE_TASK_DATA_SUCCESS,
            CourseConstant.SAVE_TASK_DATA_FAIL,
            dispatch
        );
    } catch (err) {
        dispatch({
            type: CourseConstant.SAVE_TASK_DATA_FAIL,
            payload: "",
            error: CourseConstant.DEFAULTERR,
        });
    }
};