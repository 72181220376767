import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { alertNotification } from '../../redux/action';
import { deleteQuestion, getQuestions } from '../../redux/action/forumActions';
import { ForumConstant } from '../../redux/constant';
import SaveQuestionModal from './saveQuestion';
import QuestionViewModal from './viewQuestion';
const QuestionList = () => {
    const [saveTaskPopup, setSaveTaskPopup] = useState(false);
    const [viewTaskPopup, setViewTaskPopup] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { courseId } = useParams();
    const forum = useSelector((state) => state.forum);
    const { questions } = forum
    let userDetail = JSON.parse(localStorage.getItem('userDetail'));
    useEffect(() => {
        dispatch(getQuestions(courseId));
    }, []);

    useEffect(() => {
        const { type, error } = forum;
        if (type === ForumConstant.SAVE_QUESTION_SUCCESS) {
            dispatch(alertNotification(error, 'success'));
            setSelectedQuestion(null);
            setSaveTaskPopup(false);
            dispatch(getQuestions(courseId));
        }
        if (type === ForumConstant.SAVE_QUESTION_FAIL) {
            dispatch(alertNotification(error, 'fail'));
        }

        if (type === ForumConstant.DELETE_QUESTION_SUCCESS) {
            dispatch(alertNotification(error, 'success'));
            dispatch(getQuestions(courseId));
        }
        if (type === ForumConstant.DELETE_QUESTION_FAIL) {
            dispatch(alertNotification(error, 'fail'));
        }
    }, [forum]);
    return (
        <>
            {
                userDetail?.role === 'Student' &&
                <div className="row">
                    <div className="col-md-12 col-12">
                        <div
                            className="mentor-booking"
                            style={{ width: '200px', marginBottom: '10px', float: 'right' }}
                        >
                            <a
                                className="btn apt-btn"
                                onClick={() => {
                                    setSaveTaskPopup(true);
                                }}
                            >
                                Ask Question
                            </a>
                        </div>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="card card-table">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-hover table-center mb-0">
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {questions?.map((task, i) => {
                                            const { _id, title, content, type, color } = task;
                                            return (
                                                <tr key={_id}>
                                                    <td>{title}</td>
                                                    <td>
                                                        <span
                                                            className="btn btn-sm bg-primary-light m-2"
                                                            title="Edit Question"
                                                            onClick={() => {
                                                                setSelectedQuestion(task);
                                                                setSaveTaskPopup(true);
                                                            }}
                                                        >
                                                            <i className="fas fa-pen" />
                                                        </span>
                                                        <Link
                                                            to={`/${userDetail?.role === 'Teacher' ? 'mentor' : 'student'}/question/${_id}`}
                                                            className="btn btn-sm bg-primary-light m-2"
                                                        >
                                                            <i className="far fa-eye" /> View Question
                                                        </Link>
                                                        {
                                                            userDetail?.role === 'Teacher' &&
                                                            <span
                                                                className="btn btn-sm bg-danger-light m-2"
                                                                title="Delete Question"
                                                                onClick={() => {
                                                                    if (window.confirm("Delete answer?")) {
                                                                        dispatch(deleteQuestion(_id))

                                                                    }
                                                                }}
                                                            >
                                                                <i className="fas fa-trash" />
                                                            </span>
                                                        }
                                                        
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SaveQuestionModal
                courseId={courseId}
                selectedQuestion={selectedQuestion}
                showPopup={saveTaskPopup}
                onClose={() => {
                    setSelectedQuestion(null);
                    setSaveTaskPopup(false);
                }}
            />
            <QuestionViewModal
                selectedQuestion={selectedQuestion}
                showPopup={viewTaskPopup}
                onClose={() => {
                    setSelectedQuestion(null);
                    setViewTaskPopup(false);
                }}
            />
        </>
    );
};

export default QuestionList;
