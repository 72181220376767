import {
    authHeader,
    endPointConnect
} from "../../utils/authHelper";
import { ProfileConstant } from "../constant";
  
  export const getProfileData = (id) => (dispatch) => {
    try {
      const requestData = {
        method: "GET",
        headers: authHeader()
      };
      endPointConnect(
        "user/"+id,
        requestData,
        ProfileConstant.GET_PROFILE_DATA_SUCCESS,
        ProfileConstant.GET_PROFILE_DATA_FAIL,
        dispatch
      );
    } catch (err) {
      dispatch({
        type: ProfileConstant.GET_PROFILE_DATA_FAIL,
        payload: "",
        error: ProfileConstant.DEFAULTERR,
      });
    }
  };

  export const saveProfile = (data) => (dispatch) => {
    try {
      const requestData = {
        method: "PUT",
        headers: authHeader(),
        body: JSON.stringify(data),
      };
      endPointConnect(
        "user/update",
        requestData,
        ProfileConstant.SAVE_PROFILE_DATA_SUCCESS,
        ProfileConstant.SAVE_PROFILE_DATA_FAIL,
        dispatch
      );
    } catch (err) {
      dispatch({
        type: ProfileConstant.SAVE_PROFILE_DATA_FAIL,
        payload: "",
        error: ProfileConstant.DEFAULTERR,
      });
    }
  };
  