import React from 'react';
import { Link } from 'react-router-dom';
import {
  ICON_1,
  ICON_2,
  ICON_3,
  IMG_1,
  IMG_2,
  IMG_3,
  IMG_4,
  IMG_5,
  IMG_6,
  IMG_7,
  IMG_8,
} from '../../constant/imagePaths';

export default function HomePage() {
  return (
    <div>
      {/* /Home Banner */}
      <section className="section how-it-works">
        <div className="container">
          <div className="section-header text-center">
            <span>Genius Mentors Flow</span>
            <h2>How does it works ?</h2>
            <p className="sub-title">
              Are you looking to join online institutions? Now it's very simple,
              Sign up with mentoring
            </p>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <Link to={localStorage.getItem('userDetail') ? '' : '/register'}>
                <div className="feature-box text-center">
                  <div className="feature-header">
                    <div className="feature-icon">
                      <span className="circle" />
                      <i>
                        <img src={ICON_1} alt="" />
                      </i>
                    </div>
                    <div className="feature-cont">
                      <div className="feature-text">Sign up</div>
                    </div>
                  </div>
                  <p className="mb-0">
                    Are you looking to join online Learning? Now it's very
                    simple, Now Sign up
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="feature-box text-center">
                <div className="feature-header">
                  <div className="feature-icon">
                    <span className="circle" />
                    <i>
                      <img src={ICON_2} alt="" />
                    </i>
                  </div>
                  <div className="feature-cont">
                    <div className="feature-text">Collaborate</div>
                  </div>
                </div>
                <p className="mb-0">
                  Collaborate on your own timing, by scheduling with mentor
                  booking
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="feature-box text-center">
                <div className="feature-header">
                  <div className="feature-icon">
                    <span className="circle" />
                    <i>
                      <img src={ICON_3} alt="" />
                    </i>
                  </div>
                  <div className="feature-cont">
                    <div className="feature-text">Improve &amp; Get Back</div>
                  </div>
                </div>
                <p className="mb-0">
                  you can gather different skill set, and you can become mentor
                  too
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section popular-courses">
        <div className="container">
          <div className="section-header text-center">
            <span>Mentoring Goals</span>
            <h2>Popular Mentors</h2>
            <p className="sub-title">
              Do you want to move on next step? Choose your most popular leaning
              mentors, it will help you to achieve your professional goals.
            </p>
          </div>
          {/* <div className="owl-carousel owl-theme"> */}
        </div>
        {/* </div> */}
      </section>
      {/* Path section start */}
      <section className="section path-section">
        <div className="section-header text-center">
          <div className="container">
            <span>Choose the</span>
            <h2>Different All Learning Paths</h2>
            {/* <p className="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p> */}
          </div>
        </div>
        <div className="learning-path-col">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3">
                <div className="large-col">
                  <Link to="/app/Pages/search" className="large-col-image">
                    <div className="image-col-merge">
                      <img src={IMG_1} alt="" />
                      <div className="text-col">
                        <h5>French</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="large-col">
                  <Link to="/app/Pages/search" className="large-col-image">
                    <div className="image-col-merge">
                      <img src={IMG_2} alt="" />
                      <div className="text-col">
                        <h5>Mathematics</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="large-col">
                  <Link to="/app/Pages/search" className="large-col-image">
                    <div className="image-col-merge">
                      <img src={IMG_3} alt="" />
                      <div className="text-col">
                        <h5>Social Sciences</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="large-col">
                  <Link to="/app/Pages/search" className="large-col-image">
                    <div className="image-col-merge">
                      <img src={IMG_4} alt="" />
                      <div className="text-col">
                        <h5>Humanities</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="large-col">
                  <Link to="/app/Pages/search" className="large-col-image">
                    <div className="image-col-merge">
                      <img src={IMG_5} alt="" />
                      <div className="text-col">
                        <h5>Science and Technology</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="large-col">
                  <Link to="/app/Pages/search" className="large-col-image">
                    <div className="image-col-merge">
                      <img src={IMG_6} alt="" />
                      <div className="text-col">
                        <h5>Business Studies</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="large-col">
                  <Link to="/app/Pages/search" className="large-col-image">
                    <div className="image-col-merge">
                      <img src={IMG_7} alt="" />
                      <div className="text-col">
                        <h5>The Arts</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="large-col">
                  <Link to="/app/Pages/search" className="large-col-image">
                    <div className="image-col-merge">
                      <img src={IMG_8} alt="" />
                      <div className="text-col">
                        <h5>Computer Studies</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="view-all text-center">
              <a href="#" className="btn btn-primary">
                View All
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* Path section end */}
      {/* Blog Section */}
      {/* <section className="section section-blogs">
        <div className="container">
          <div className="section-header text-center">
            <span>Lorem ipsum dolor</span>
            <h2>The Blogs &amp; News</h2>
            <p className="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <div className="row blog-grid-row">
            <div className="col-md-6 col-lg-3 col-sm-12">
              <div className="blog grid-blog">
                <div className="blog-image">
                  <Link to="/app/Blog/blog-details"><img className="img-fluid" src={BLOG_01} alt="Post Image" /></Link>
                </div>
                <div className="blog-content">
                  <ul className="entry-meta meta-item">
                    <li>
                      <div className="post-author">
                        <Link to="/app/Blog/blog-details"><span>Tyrone Roberts</span></Link>
                      </div>
                    </li>
                    <li><i className="far fa-clock" /> 4 Dec 2019</li>
                  </ul>
                  <h3 className="blog-title"><Link to="/app/Blog/blog-details">What is Lorem Ipsum? Lorem Ipsum is simply</Link></h3>
                  <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-12">
              <div className="blog grid-blog">
                <div className="blog-image">
                  <Link to="/app/Blog/blog-details"><img className="img-fluid" src={BLOG_02} alt="Post Image" /></Link>
                </div>
                <div className="blog-content">
                  <ul className="entry-meta meta-item">
                    <li>
                      <div className="post-author">
                        <Link to="/app/Blog/blog-details"><span>Brittany Garcia</span></Link>
                      </div>
                    </li>
                    <li><i className="far fa-clock" /> 3 Dec 2019</li>
                  </ul>
                  <h3 className="blog-title"><Link to="/app/Blog/blog-details">Contrary to popular belief, Lorem Ipsum is</Link></h3>
                  <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-12">
              <div className="blog grid-blog">
                <div className="blog-image">
                  <Link to="/app/Blog/blog-details"><img className="img-fluid" src={BLOG_03} alt="Post Image" /></Link>
                </div>
                <div className="blog-content">
                  <ul className="entry-meta meta-item">
                    <li>
                      <div className="post-author">
                        <Link to="/app/Blog/blog-details"><span>Allen Davis</span></Link>
                      </div>
                    </li>
                    <li><i className="far fa-clock" /> 3 Dec 2019</li>
                  </ul>
                  <h3 className="blog-title"><Link to="/app/Blog/blog-details">The standard chunk of Lorem Ipsum used</Link></h3>
                  <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-12">
              <div className="blog grid-blog">
                <div className="blog-image">
                  <Link to="/app/Blog/blog-details"><img className="img-fluid" src={BLOG_04} alt="Post Image" /></Link>
                </div>
                <div className="blog-content">
                  <ul className="entry-meta meta-item">
                    <li>
                      <div className="post-author">
                        <Link to="/app/Blog/blog-details"><span>William Lawrence</span></Link>
                      </div>
                    </li>
                    <li><i className="far fa-clock" /> 2 Dec 2019</li>
                  </ul>
                  <h3 className="blog-title"><Link to="/app/Blog/blog-details">The standard Lorem Ipsum passage, used</Link></h3>
                  <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="view-all text-center">
            <Link to="/app/Blog/blog-list" className="btn btn-primary">View All</Link>
          </div>
        </div>
      </section>
      <section className="section statistics-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="statistics-list text-center">
                <span>500+</span>
                <h3>Happy Clients</h3>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="statistics-list text-center">
                <span>120+</span>
                <h3>Online Appointments</h3>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="statistics-list text-center">
                <span>100%</span>
                <h3>Job Satisfaction</h3>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}
