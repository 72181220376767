import { combineReducers } from 'redux';
import alertReducers from './alertReducer';
import authReducers from './authReducer';
import courseReducers from './courseReducer';
import forumReducers from './forumReducer';
import homeworkReducer from './homeworkReducer';
import kanbanBoardReducers from './kanbanBoardReducer';
import paymentReducers from './paymentReducer';
import profileReducers from './profileReducer';
import timeslotReducers from './timeslotReducer';
import vocabReducer from './vocabReducer';

export default combineReducers({
  alertNotification: alertReducers,
  auth: authReducers,
  profile: profileReducers,
  payment: paymentReducers,
  course: courseReducers,
  kanbanBoard: kanbanBoardReducers,
  timeslot: timeslotReducers,
  forum: forumReducers,
  homework: homeworkReducer,
  vocab: vocabReducer,
});
